import { Controller } from "@hotwired/stimulus";
import { buildSpinner } from "../helpers";
export default class extends Controller {
  static targets = [
    "modalContainer",
    "modalContent",
    "submit",
    "scrollable",
    "toggle",
    "error",
  ];

  connect() {
    this.initialHtml = this.element.innerHTML;
    this.initialButtonHtml = this.submitTarget.innerHTML;
    this.element.addEventListener("turbo:submit-start", this.onSubmitStart);
    this.element.addEventListener("turbo:submit-end", this.onSubmitEnd);
    window.addEventListener("click", this.onWindowClick);
  }

  reset() {
    this.element.innerHTML = this.initialHtml;
  }

  openModal(event) {
    event.stopPropagation();
    this.modalContainerTarget.classList.add("active");
  }

  hideModal() {
    this.modalContainerTarget.classList.remove("active");
  }

  onSubmitStart = () => {
    this.showSpinner();
  };

  onSubmitEnd = (event) => {
    this.hideSpinner();

    if (event.detail.success) {
      this.clearErrors();
      this.scrollableTarget.scrollTop = 0;
      this.hideModal();
      return;
    }

    this.renderError(event.detail.fetchResponse.response);
  };

  modalIsOpen() {
    return this.modalContainerTarget.classList.contains("active");
  }

  onWindowClick = (event) => {
    if (this.modalContentTarget.contains(event.target) || !this.modalIsOpen()) {
      return;
    }

    this.hideModal();
  };

  clearErrors() {
    this.errorTarget.innerText = "";
  }

  renderError(response) {
    let msg;
    switch (response.status) {
      case 404:
        msg = "No results, try widening your search.";
        break;
      default:
        msg = "Something went wrong, please try again.";
    }

    this.errorTarget.innerText = msg;
  }

  showSpinner() {
    const initialWidth = this.submitTarget.offsetWidth;
    const spinner = buildSpinner();
    this.submitTarget.disabled = true;
    this.submitTarget.style.minWidth = `${initialWidth}px`;
    this.submitTarget.innerHTML = "";
    this.submitTarget.appendChild(spinner);
  }

  hideSpinner() {
    this.submitTarget.disabled = false;
    this.submitTarget.innerHTML = this.initialButtonHtml;
  }

  disconnect() {
    this.element.removeEventListener("turbo:submit-start", this.onSubmitStart);
    this.element.removeEventListener("turbo:submit-end", this.onSubmitEnd);
    window.removeEventListener("click", this.onWindowClick);
  }
}
