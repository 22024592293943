import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  initialize() {
    this.handleBlur();
    this.inputTarget.addEventListener("focus", () => this.handleFocus());
    this.inputTarget.addEventListener("blur", () => this.handleBlur());
  }

  handleFocus() {
    this.element.classList.add("focused");
  }

  handleBlur() {
    if (this.inputTarget.value === "") {
      this.element.classList.remove("focused");
    } else {
      this.element.classList.add("focused");
    }
  }
}
