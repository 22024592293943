// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import { setCookie } from "./helpers";
import "./channels";
import "./controllers";
import LocalTime from "local-time";
import jstz from "jstz";
import "chartkick/chart.js";
import "trix";
import "@rails/actiontext";

ActiveStorage.start();
LocalTime.start();

const timezone = jstz.determine();

setCookie("timezone", timezone.name());
