import { Controller } from "@hotwired/stimulus";

// This controller is used for lazy loading infinite scroll pages that require "display: contents" styling
// Turbo fails to update the turbo frame if it's style is set to "display: contents" before the frame has loaded
export default class extends Controller {
  initialize() {
    const observerConfig = {
      attributes: false,
      childList: true,
      subtree: false,
    };

    this.observer = new MutationObserver((mutationList, observer) =>
      this.handleMutation(mutationList, observer)
    );

    this.observer.observe(this.element, observerConfig);
  }

  handleMutation = (mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type !== "childList") return;

      this.setDisplayStyle();
    }
  };

  setDisplayStyle() {
    this.element.classList.add("contents");
  }
}
