import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";
export default class extends Controller {
  static targets = ["trigger", "modal"];

  initialize() {
    this.modalEnabled = false;
    this.modalNodes = this.modalTarget.childNodes;
    this.modalTarget.addEventListener("click", (e) => {
      this.handleBackgroundClick(e);
    });

    window.addEventListener(
      "resize",
      throttle(() => this.handleResize(), 750)
    );
  }

  handleResize() {
    if (window.innerWidth < 768 || !this.modalEnabled) return;
    this.hideModal();
  }

  toggleDisplay() {
    this.modalEnabled = !this.modalEnabled;
    if (this.modalEnabled) return this.displayModal();
    this.hideModal();
  }

  displayModal() {
    this.modalEnabled = true;
    this.modalTarget.classList.add("active");
  }

  hideModal() {
    this.modalEnabled = false;
    this.modalTarget.classList.remove("active");
  }

  handleBackgroundClick(e) {
    if (e.target === this.modalTarget) return this.toggleDisplay();
  }
}
