import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["email", "password", "loginBtn"];

  initialize() {
    this.validateInput();
  }

  validateInput() {
    if (
      this.emailTarget.value.trim() === "" ||
      this.passwordTarget.value.trim() === ""
    ) {
      this.loginBtnTarget.disabled = true;
    } else {
      this.loginBtnTarget.disabled = false;
    }
  }
}
