import { Controller } from "@hotwired/stimulus";
import {
  findElement,
  dataAttributeToBoolean,
  clickedOnElement,
} from "../helpers";
import { hideAll } from "tippy.js";

export default class extends Controller {
  static values = {
    hideTooltips: Boolean, // Whether to hide all tooltips when this modal is opened
  };

  static targets = ["modal", "form", "error", "input", "unsavedModal"];

  connect() {
    this.customEvent = this.data.get("custom-event");
    this.closeEvent = this.data.get("close-event");
    this.successEvent = this.data.get("success-event");
    this.checkClicks = dataAttributeToBoolean(this, "check-clicks") || false;
    this.hasDropzone = dataAttributeToBoolean(this, "dropzone") || false;
    this.storeInitialValues();
    this.hideTooltips();
    window.addEventListener("global-modal:close", this.closeModal);
  }

  hideTooltips() {
    if (!this.hideTooltipsValue) return;

    hideAll();
  }

  storeInitialValues() {
    if (!this.hasInputTarget) return;

    this.initialValues = {};
    this.inputTargets.forEach((input) => {
      this.initialValues[input.name] = input.value;
    });
  }

  hasUnsavedChanges() {
    if (!this.hasInputTarget) return false;

    return this.inputTargets.some((input) => {
      return input.value !== this.initialValues[input.name];
    });
  }

  showUnsavedModal() {
    this.unsavedModalTarget.classList.add("active");
  }

  hideUnsavedModal() {
    this.unsavedModalTarget.classList.remove("active");
  }

  hideModal() {
    // Cancel hiding the modal if a nested modal is currently open
    const childModal = this.data.get("child") || null;
    if (childModal && this.modalIsOpen(childModal)) return;

    if (this.hasUnsavedChanges() && this.hasUnsavedModalTarget) {
      this.showUnsavedModal();
      return;
    }

    // Allow parent modal time to see if nested modal was just closed
    setTimeout(() => {
      this.dispatchCloseEvent();
      this.closeModal();
    }, 1);
  }

  modalIsOpen(childModal) {
    const childModalElement = findElement(childModal);
    return childModalElement && childModalElement.innerHTML.trim().length > 0;
  }

  dispatchCloseEvent() {
    if (!this.closeEvent) return;
    const event = new CustomEvent(this.closeEvent);
    window.dispatchEvent(event);
  }

  closeModal = () => {
    window.removeEventListener("global-modal:close", this.closeModal);
    this.element.parentElement.removeAttribute("src");
    this.modalTarget.parentElement.remove();
  };

  submitEnd(e) {
    if (e.detail.success) {
      if (this.customEvent) {
        const event = new CustomEvent(this.customEvent);
        window.dispatchEvent(event);
      }

      if (this.hasUnsavedModalTarget) {
        this.unsavedModalTarget.remove();
      }

      return this.hideModal();
    }

    if (this.hasErrorTarget) {
      this.renderError(e.detail.fetchResponse.response);
    }
  }

  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal();
    }
  }

  closeBackground(e) {
    if (this.data.get("nobackdrop")) {
      this.handleCloseWithoutBackdrop(e);
    } else {
      this.handleCloseWithBackdrop(e);
    }
  }

  handleCloseWithBackdrop(e) {
    if (e && e.target !== this.element) return;
    this.hideModal();
  }

  handleCloseWithoutBackdrop(e) {
    if (e && this.modalTarget.contains(e.target)) return;
    if (this.checkClicks && clickedOnElement(e, this.modalTarget)) return;
    if (this.hasDropzone && this.eventFromDropzone(e)) return;

    this.hideModal();
  }

  resetForm() {
    this.formTarget.reset();
  }

  renderError(response) {
    let msg;
    switch (response.status) {
      case 404:
        msg = this.errorTarget.getAttribute("data-not-found") || "Not Found";
        break;
      default:
        msg = response.statusText;
    }

    this.errorTarget.textContent = msg;
    this.errorTarget.classList.add("active");
  }

  eventFromDropzone(e) {
    return e.target.classList.contains("dz-hidden-input");
  }

  fireSuccessEvent(e) {
    if (!this.successEvent || !e.detail.success) return;
    const event = new CustomEvent(this.successEvent);
    window.dispatchEvent(event);
  }

  disconnect() {
    window.removeEventListener("global-modal:close", this.closeModal);
    this.dispatchCloseEvent();
  }
}
