import { Controller } from "@hotwired/stimulus";
import { findElement } from "../helpers";

export default class extends Controller {
  initialize() {
    this.target = findElement(this.data.get("selector"));

    if (!this.target) return;

    this.target.href = this.getUpdatedPath();
  }

  getParams() {
    const currentUrl = this.data.get("url") || window.location.href;
    const paramsIndex = currentUrl.indexOf("?");

    if (paramsIndex === -1) return "";

    return currentUrl.slice(paramsIndex);
  }

  getUpdatedPath() {
    const newPath = this.data.get("new-path");
    const params = this.getParams();

    return newPath + params;
  }
}
