import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import Dropzone from "dropzone";
import {
  getMetaValue,
  findElement,
  removeElement,
  insertAfter,
  flashNow,
} from "../helpers";

export default class extends Controller {
  static targets = ["input", "error", "updateButton", "existingFileInput"];

  connect() {
    this.required = this.data.get("required") === "true";
    this.flashErrors = this.data.get("flash-errors") === "true";
    this.maxFiles = this.data.get("maxFiles")
      ? parseInt(this.data.get("maxFiles"))
      : 1;
    this.maxFilesize = this.data.get("maxsize")
      ? parseInt(this.data.get("maxsize"))
      : 10;
    this.acceptedFiles =
      this.data.get("acceptedFiles") || ".jpeg,.jpg,.png,.gif";
    this.addRemoveLinks = this.data.get("addRemoveLinks") || true;
    this.existingFile = this.data.get("existingFile") || null;
    this.existingFilesize = parseInt(this.data.get("existingFilesize"));
    this.existingFilename = this.data.get("existingFilename");
    this.url = this.inputTarget.getAttribute("data-direct-upload-url");
    this.headers = { "X-CSRF-Token": getMetaValue("csrf-token") };
    this.submitBtn = this.data.get("submitId")
      ? findElement(`#${this.data.get("submitId")}`)
      : null;
    this.customEvent = this.data.get("customEvent");
    this.previousFile = null;
    this.thumbnailWidth = parseInt(this.data.get("thumbnail-width") || 180);
    this.thumbnailHeight = parseInt(this.data.get("thumbnail-height") || 180);
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    this.displayExistingFile();
    this.setMultiErrorContainer();
    Dropzone.autoDiscover = false;
  }

  setMultiErrorContainer() {
    const multiErrorId = this.data.get("multiErrorId");
    if (!multiErrorId) return;

    this.multiErrorContainer = findElement(`#${multiErrorId}`);
  }

  hideFileInput() {
    this.inputTarget.classList.add("hidden");
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      this.clearErrors();

      if (this.previousFile && this.maxFiles === 1)
        this.dropZone.removeFile(this.previousFile);

      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      });
    });

    this.dropZone.on("removedfile", (file) => {
      file.accepted && this.clearErrors();
      file.controller && removeElement(file.controller.hiddenInput);
      this.dispatchCustomEvent();
      this.onFileRemoved();

      if (this.maxFiles > 1 && this.dropZone.files.length === 0) {
        this.multiErrorContainer.textContent = "";
        this.setDisabled();
      }
    });

    this.dropZone.on("maxfilesexceeded", (file) => {
      if (this.maxFiles > 1) return;

      this.dropZone.removeFile(file);
    });

    this.dropZone.on("error", (file, message) => {
      this.renderError(message);
      this.showUpdateButton();

      if (this.maxFiles > 1) {
        return this.renderMultiUploadError();
      }

      this.dropZone.removeFile(file);
    });

    this.dropZone.on("processing", () => {
      this.setDisabled();
      this.hideUpdateButton();
    });

    this.dropZone.on("success", (file) => {
      this.previousFile = file;
      this.clearErrors();
      this.showUpdateButton();
    });

    this.dropZone.on("queuecomplete", this.onQueueComplete.bind(this));
  }

  onQueueComplete() {
    if (this.required && this.dropZone.files.length === 0) {
      this.setDisabled();
      return;
    }

    this.clearDisabled();
    this.dispatchCustomEvent();
  }

  setDisabled() {
    if (!this.submitBtn) return;

    this.submitBtn.disabled = true;
  }

  clearDisabled() {
    if (!this.submitBtn) return;

    this.submitBtn.disabled = false;
  }

  dispatchCustomEvent() {
    if (!this.customEvent) return;

    const event = new CustomEvent(this.customEvent);

    setTimeout(() => {
      window.dispatchEvent(event);
    });
  }

  onFileRemoved = () => {
    if (!this.hasExistingFileInputTarget) return;
    this.existingFileInputTarget.value = "";
  };

  displayExistingFile() {
    if (!this.existingFile) return;

    let mockFile = { name: this.existingFilename, size: this.existingFilesize };
    this.dropZone.emit("addedfile", mockFile);
    this.dropZone.emit("thumbnail", mockFile, this.existingFile);
    this.dropZone.emit("complete", mockFile);
  }

  renderError(message) {
    if (this.flashErrors) return flashNow("alert", message);
    if (!this.hasErrorTarget) return;

    this.errorTarget.classList.remove("hidden");
    const errorSpan = document.createElement("span");
    errorSpan.textContent = message;
    this.errorTarget.appendChild(errorSpan);
  }

  renderMultiUploadError(
    message = "Some files were not allowed, view the list above for more details"
  ) {
    if (!this.multiErrorContainer) return;

    this.multiErrorContainer.textContent = message;
  }

  clearErrors() {
    if (!this.hasErrorTarget) return;

    this.errorTarget.classList.add("hidden");
    this.errorTarget.textContent = "";
  }

  openFileBrowser() {
    this.dropZone.hiddenFileInput.click();
  }

  hideUpdateButton() {
    if (!this.hasUpdateButtonTarget) return;
    this.updateButtonTarget.classList.add("opacity-0");
  }

  showUpdateButton() {
    if (!this.hasUpdateButtonTarget) return;
    setTimeout(() => {
      this.updateButtonTarget.classList.remove("opacity-0");
    }, 2500);
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", (event) => {
      this.uploadRequestDidProgress(event);
    });
  }

  uploadRequestDidProgress(event) {
    const progress = (event.loaded / event.total) * 100;

    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFilesize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
    thumbnailWidth: controller.thumbnailWidth,
    thumbnailHeight: controller.thumbnailHeight,
  });
}
