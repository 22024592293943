import { Controller } from "@hotwired/stimulus";
import { findElement } from "../helpers";

export default class extends Controller {
  static targets = ["userable", "color", "required", "error", "submitBtn"];

  // Add disabled style to button, validate form, override default
  connect() {
    this.disableSubmit();
    this.errorTargets.forEach((error) => {
      error.classList.add("hidden");
    });
    this.element.addEventListener("submit", (event) =>
      this.handleSubmit(event)
    );
    this.validateForm();
  }

  // Add / Remove disabled style based on current inputs
  validateForm(event) {
    if (event && event.target.name === "hub[name]")
      this.checkNameLength(event.target.value, event.target.maxLength);

    setTimeout(() => {
      const uploadPreview = findElement(this.element, ".dz-complete");
      const colorPresent = this.validateColors();
      const namePresent = this.validateName();
      if (uploadPreview && colorPresent && namePresent) {
        return this.enableSubmit();
      }
      this.disableSubmit();
    });
  }

  preventDefault(e) {
    e.preventDefault();
  }

  enableSubmit() {
    this.element.removeEventListener("submit", this.preventDefault);
    this.submitBtnTarget.classList.remove("disabled");
  }

  disableSubmit() {
    this.element.addEventListener("submit", this.preventDefault);
    this.submitBtnTarget.classList.add("disabled");
  }

  checkNameLength(nameInput, maxLength) {
    if (nameInput.length === 0) {
      this.renderError("name", "Hub name can't be blank");
    } else if (nameInput.length >= maxLength) {
      this.renderError(
        "name",
        `Maximum of ${maxLength} characters has been reached`
      );
    } else {
      this.clearError("name");
    }
  }

  validateUpload() {
    if (!findElement(this.element, ".dz-complete")) return false;
    this.clearError("image");
    return true;
  }

  validateColors() {
    if (!this.colorTargets.some((color) => color.checked)) return false;
    this.clearError("color");
    return true;
  }

  validateName() {
    if (!this.requiredTarget.value.trim()) return false;
    return true;
  }

  findErrorElement(errorId) {
    return this.errorTargets.find(
      (span) => span.getAttribute("data-error-id") === errorId
    );
  }

  renderError(errorId, errorMsg) {
    this.findErrorElement(errorId).textContent = errorMsg;
    this.findErrorElement(errorId).classList.remove("hidden");
  }

  clearError(errorId) {
    this.findErrorElement(errorId).textContent = "";
    this.findErrorElement(errorId).classList.add("hidden");
  }

  handleSubmit() {
    let valid = true;
    if (!this.validateColors()) {
      valid = false;
      this.renderError("color", "Hub color must be selected");
    }

    if (!this.validateName()) {
      valid = false;
      this.renderError("name", "Hub name can't be blank");
    }

    if (!this.validateUpload()) {
      valid = false;
      this.renderError("image", "Hub image required");
    }

    if (!valid) return this.element.scrollIntoView({ behavior: "smooth" });
  }

  toggleSelect(event) {
    this.userableTargets.forEach(
      (checkbox) => (checkbox.checked = event.target.checked)
    );
  }

  toggleUserableSelect(event) {
    const hiddenInput = event.target.previousElementSibling;
    hiddenInput.checked = !hiddenInput.checked;
  }
}
