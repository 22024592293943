import { Controller } from "@hotwired/stimulus";
import { SCREEN_MD } from "../breakpoints";

export default class extends Controller {
  static targets = ["form", "input", "response", "button"];

  initialize() {
    this.formTarget.addEventListener("submit", (e) => this.handleSubmit(e));
    this.modalTarget = document.querySelector(this.data.get("modal"));
    this.authToken = this.data.get("token");
    this.authEmail = this.data.get("email");
    this.requestPath = this.data.get("request-path");
    this.profilePath = this.data.get("profile-path");
    this.profileParams = this.data.get("profile-params");
    this.organizationId = this.data.get("organization");
    this.organizationRole = this.data.get("role");

    if (window.innerWidth >= SCREEN_MD) {
      this.inputTarget.placeholder = this.inputTarget.getAttribute(
        "data-desktop-placeholder"
      );
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const contactInput = this.inputTarget.value.trim();
    if (contactInput.length === 0) return;

    this.setLoading();
    const auth = `Token token=${this.authToken}, email=${this.authEmail}`;
    let requestBody;

    if (this.organizationId) {
      requestBody = {
        organization_invite: {
          contact: contactInput,
          organization_id: this.organizationId,
          role: this.organizationRole,
        },
      };
    } else {
      requestBody = { invite: { contact: contactInput } };
    }

    fetch(this.requestPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: auth,
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        this.clearLoading();
        if (res.ok) {
          res.json().then((json) => this.renderSuccess(json.success));
        } else {
          res.json().then((json) => this.handleErrors(json.errors));
        }
      })
      .catch((err) => {
        this.clearLoading();
        this.renderError(err);
      });
  }

  setLoading() {
    this.responseTarget.textContent = "";
    this.buttonTarget.disabled = true;
    this.buttonTarget.classList.add("loading");
  }

  clearLoading() {
    this.buttonTarget.disabled = false;
    this.buttonTarget.classList.remove("loading");
  }

  handleErrors(errors) {
    const existingUserError = errors.find((err) =>
      err.includes("Existing user")
    );
    if (existingUserError) {
      const splitError = existingUserError.split(" ");
      const existingUserId = splitError[2];
      return this.renderProfile(existingUserId);
    }
    this.renderError(errors[0]);
  }

  renderProfile(userId) {
    const src = `${this.profilePath}/${userId}?${this.profileParams}`;
    this.modalTarget.setAttribute("src", src);
    this.formTarget.reset();
  }

  renderError(message) {
    this.responseTarget.textContent = message;
    this.responseTarget.classList.remove("success");
    this.responseTarget.classList.add("error");
    this.responseTarget.classList.remove("hidden");
  }

  renderSuccess(message) {
    this.responseTarget.textContent = message;
    this.responseTarget.classList.remove("error");
    this.responseTarget.classList.add("success");
    this.responseTarget.classList.remove("hidden");
    this.element.classList.add("active");
    this.formTarget.reset();

    setTimeout(() => this.responseTarget.classList.add("hidden"), 10000);
    setTimeout(() => this.element.classList.remove("active"), 5000);
  }
}
