import { Controller } from "@hotwired/stimulus";
import { flashNow, buildSpinner, findElement } from "../helpers";
import autocomplete from "autocompleter";

export default class extends Controller {
  initialize() {
    this.options = [];
    this.initialValue = this.element.value;

    this.autocomplete = autocomplete({
      input: this.element,
      debounceWaitMs: 300,
      render: (res, input) => this.handleRender(res, input),
      fetch: (text, update) => this.handleUpdate(text, update),
      onSelect: (item) => this.handleSelect(item),
    });

    this.element.parentElement.appendChild(buildSpinner());
    this.element.addEventListener("input", (e) => this.handleInput(e));
    this.element.addEventListener("blur", (e) => this.handleBlur(e));
  }

  setFetchOptions() {
    const authToken = this.data.get("token");
    const authEmail = this.data.get("email");
    const auth = `Token token=${authToken}, email=${authEmail}`;

    return {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: auth,
      },
    };
  }

  handleUpdate(text, update) {
    if (text.trim().length === 0) return;

    fetch(`${this.data.get("url")}?q=${text}`, this.setFetchOptions())
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            update(json);
            this.endLoading();
            this.options = json;
            if (
              this.element.value.length <= 1 ||
              document.activeElement !== this.element
            )
              return;
            this.setActive();
          });
        } else {
          flashNow("alert", res.statusText);
        }
      })
      .catch((error) => flashNow("alert", error));
  }

  handleRender(res, input) {
    const div = document.createElement("div");
    const regex = new RegExp(input, "gi");
    div.setAttribute("data-value", res.label);
    const highlightedResult = res.label.replace(
      regex,
      '<mark class="highlight">$&</mark>'
    );
    div.innerHTML = highlightedResult;
    return div;
  }

  handleSelect(item) {
    this.element.value = item.label;
    if (findElement(".autocomplete")) return this.element.blur();
    this.clearActive();
    this.endLoading();
  }

  handleInput(e) {
    if (e.target.value.trim().length <= 1) {
      this.clearActive();
      this.endLoading();
    } else {
      this.startLoading();
    }
  }

  // Allow input to be valid if casing does not match original
  handleBlur(e) {
    const dropdown = findElement(".autocomplete");
    if (dropdown) dropdown.remove();
    this.clearActive();

    if (this.element.value === this.initialValue) return;

    e.target.value = e.target.value.trim();
    const matchingValue = this.options.find(
      (row) => row["label"].toLowerCase() === e.target.value.toLowerCase()
    );
    if (matchingValue) {
      e.target.value = matchingValue.label;
      this.clearErrors();
    } else {
      this.renderError();
    }
  }

  clearErrors() {
    if (!this.data.get("validate")) return;

    this.element.parentElement.parentElement
      .querySelectorAll(".error")
      .forEach((el) => el.remove());
  }

  renderError() {
    if (!this.data.get("validate")) return;
    this.clearErrors();

    const span = document.createElement("span");
    span.className = "error";
    span.textContent = "Please select an option from the list";
    this.element.parentElement.parentElement.appendChild(span);
  }

  startLoading() {
    this.element.parentElement.classList.add("loading");
  }

  endLoading() {
    this.element.parentElement.classList.remove("loading");
  }

  setActive() {
    this.element.parentElement.classList.add("active");
  }

  clearActive() {
    this.element.parentElement.classList.remove("active");
    this.endLoading();
  }
}
