import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.delay = this.data.get("delay") || 500;

    setTimeout(() => {
      this.element.classList.remove("hidden");
    }, parseInt(this.delay));
  }
}
