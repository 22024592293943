import { Controller } from "@hotwired/stimulus";
import { EMAIL_REGEX, URL_REGEX } from "../regex";
import * as chrono from "chrono-node";

export default class extends Controller {
  static values = {
    displayPopup: Boolean, // Whether to display a popup for the first suggestion
    timeZone: String, // Current user's abbreviated time zone
    newActivityPath: String, // Path to the new activity form
    organizationId: String, // Organization ID for the new activity
  };

  static targets = [
    "dateLink", // One or more links created from the parsed dates
    "message", // The container holding the chat message's content
  ];

  connect() {
    this.parseSuggestions();
  }

  disconnect() {
    this.element.removeAttribute("data-controller");
  }

  dateLinkTargetConnected(element) {
    const isFirstSuggestion = element === this.dateLinkTargets[0];

    if (isFirstSuggestion && this.displayPopupValue) {
      this.showTooltip(element);
    }
  }

  showTooltip(element) {
    const event = new CustomEvent("tooltip:show");
    element.dispatchEvent(event);
  }

  // Don't scan parts of the message that are links or email addresses
  sanitizeMessage() {
    let message = this.messageTarget.innerHTML;
    message = message.replace(EMAIL_REGEX, "");
    message = message.replace(URL_REGEX, "");
    return message;
  }

  parseSuggestions() {
    const sanitizedMessage = this.sanitizeMessage();
    const results = chrono.parse(sanitizedMessage, {
      forwardDate: true,
      timezone: this.timeZoneValue,
    });

    if (results.length === 0) {
      return;
    }

    this.highlightDates(results);
  }

  buildSuggestionUrl(result) {
    const timeZone = this.timeZoneValue;
    const organizationId = this.organizationIdValue;
    const startTime = encodeURIComponent(result.start.date().toISOString());
    let endTime = "";

    if (result.end) {
      endTime = encodeURIComponent(result.end.date().toISOString());
    }

    return `${this.newActivityPathValue}?start_time=${startTime}&end_time=${endTime}&time_zone=${timeZone}&organization_id=${organizationId}`;
  }

  buildSuggestionHtml(result) {
    const suggestionUrl = this.buildSuggestionUrl(result);
    const suggestionText = result.text;
    const a = document.createElement("a");

    a.href = suggestionUrl;
    a.className = "date-link";
    a.textContent = suggestionText;
    a.setAttribute("data-activity-suggestion-target", "dateLink");
    a.setAttribute("data-tooltip-content-value", "Add activity to calendar?");
    a.setAttribute("data-tooltip-hide-all-on-show-value", "true");
    a.setAttribute("data-tooltip-event", "mouseenter focus");
    a.setAttribute("data-tooltip-theme", "activity-suggestion");
    a.setAttribute("data-tooltip-target", "trigger");
    a.setAttribute("data-controller", "tooltip");
    a.setAttribute("data-turbo-frame", "global-modal");

    return a.outerHTML;
  }

  highlightDates(results) {
    let messageHtml = this.messageTarget.innerHTML;

    results.forEach((result) => {
      const suggestionHtml = this.buildSuggestionHtml(result);
      const pattern = new RegExp(result.text, "gi");
      messageHtml = messageHtml.replace(pattern, suggestionHtml);
    });

    this.messageTarget.innerHTML = messageHtml;
  }
}
