import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  initialize() {
    this.inputTarget.addEventListener("paste", (e) => {
      this.handlePaste(e);
    });
  }

  // Prefix with https on focus
  setProtocol() {
    this.inputTarget.value = "https://";
  }

  // Clear input when pasting url
  handlePaste(e) {
    e.preventDefault();
    let pasteValue = (e.clipboardData || window.clipboardData).getData("text");
    this.inputTarget.value = pasteValue;

    // Submit on paste
    this.element.requestSubmit();
  }
}
