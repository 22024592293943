import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    let links = document.getElementById("links");
    let messages = document.getElementById("messages");

    links.addEventListener("click", function handleClick() {
      document.getElementById("show_messages").classList.add("hidden");
      document.getElementById("show_links").classList.remove("hidden");
    });

    messages.addEventListener("click", function handleClick() {
      document.getElementById("show_messages").classList.remove("hidden");
      document.getElementById("show_links").classList.add("hidden");
    });
  }
}
