import { Controller } from "@hotwired/stimulus";
let debounce = require("lodash/debounce");

export default class extends Controller {
  static targets = ["query", "all", "filtered", "error"];

  initialize() {
    const delay = this.data.get("delay") || 300;
    this.search = debounce(this.search, delay).bind(this);
  }

  search(e) {
    const query = this.queryTarget.value.toLowerCase();
    const path = this.queryTarget.dataset.path + query;
    const url = this.appendOptionsTo(path);

    if (e.target.value.trim().length === 0) {
      this.displayUnfilteredList();
      return this.clearError();
    }

    if (query) {
      fetch(url).then((response) => {
        if (response.ok) {
          this.clearError();
          response
            .text()
            .then((html) => (this.filteredTarget.innerHTML = html));
        } else {
          this.renderError(response);
        }
      });
    } else {
      this.filteredTarget.innerHTML = "";
    }

    query ? this.displayFilteredList() : this.displayUnfilteredList();
  }

  displayFilteredList() {
    if (this.hasAllTarget) this.allTarget.classList.add("hidden");
    this.filteredTarget.classList.remove("hidden");
  }

  displayUnfilteredList() {
    if (this.hasAllTarget) this.allTarget.classList.remove("hidden");
    this.filteredTarget.classList.add("hidden");
  }

  appendOptionsTo(path) {
    let url = path;

    Object.keys(Object.assign({}, this.queryTarget.dataset))
      .filter((attr) => attr.startsWith("searchOptions"))
      .forEach((attr) => {
        url =
          url +
          "&" +
          attr.slice(13).replace(/^\w/, (c) => c.toLowerCase()) +
          "=" +
          this.queryTarget.dataset[attr];
      });
    return url;
  }

  clearError() {
    if (!this.hasErrorTarget) return;
    this.errorTarget.textContent = "";
    this.errorTarget.classList.add("hidden");
  }

  renderError(response) {
    this.filteredTarget.innerHTML = "";
    if (!this.hasErrorTarget) return;

    let msg;
    switch (response.status) {
      case 404:
        msg = this.errorTarget.getAttribute("data-not-found") || "Not Found";
        break;
      default:
        msg = response.statusText;
    }

    this.errorTarget.textContent = msg;
    this.errorTarget.classList.remove("hidden");
  }
}
