import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "window", "overlay"]

  open() {
    this.displayModal()
  }
  
  close() {
    this.hideModal()
  }

  openOverlay() {
    this.overlayTarget.classList.remove("hidden")
  }

  closeOverlay() {
    this.overlayTarget.classList.add("hidden")
  }

  setContent(content) {
    this.windowTarget.innerHTML = content
  }

  displayModal() {
    this.modalTarget.classList.remove("hidden")
    this.removeBackgroundScroll()
  }

  hideModal() {
    this.modalTarget.classList.add("hidden")
    this.restoreBackgroundScroll()
  }

  removeBackgroundScroll() {
    document.body.classList.add("no-scroll")
  }

  restoreBackgroundScroll() {
    document.body.classList.remove("no-scroll")
  }
}
