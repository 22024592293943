import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit", "option"];

  initialize() {
    this.minRequired = this.data.get("min")
      ? parseInt(this.data.get("min"))
      : 1;

    this.validate();
  }

  validate() {
    const selectedCount = this.optionTargets.filter(
      (option) => option.checked
    ).length;
    if (selectedCount < this.minRequired) return this.disableSubmit();

    this.enableSubmit();
  }

  enableSubmit() {
    this.submitTarget.disabled = false;
  }

  disableSubmit() {
    this.submitTarget.disabled = true;
  }
}
