import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["value", "range"];

  connect() {
    this.updateValue();
  }

  updateValue() {
    const options = this.rangeTarget.getAttribute("data-options").split(",");
    const selected = this.rangeTarget.value;
    this.valueTarget.textContent = options[selected];
  }
}
