import { Controller } from "@hotwired/stimulus";
import { isHidden } from "../helpers";

export default class extends Controller {
  static targets = ["thread", "chatMessage", "pendingNotice", "disclaimer"];

  connect() {
    this.chatId = this.data.get("id");
    this.userId = this.data.get("user");
    this.accepted = false;
    this.verifyAcceptance();
    this.handleScroll();
    this.markChatRead(this.element);
    this.updateNotifiableId();

    const config = { attributes: false, childList: true, subtree: true };
    this.observer = new MutationObserver((mutationList, observer) =>
      this.handleMutation(mutationList, observer)
    );
    this.observer.observe(this.threadTarget, config);
  }

  handleMutation = (mutationList, observer) => {
    for (const mutation of mutationList) {
      mutation.addedNodes.forEach((node) => {
        if (node.nodeName !== "TURBO-FRAME") return;

        this.verifyAcceptance();

        if (node.dataset.newMessage !== "true") {
          return;
        }

        this.markChatRead(node);
        this.handleScroll("smooth");
      });
    }
  };

  handleScroll(behavior) {
    this.element.scroll({
      top: this.element.scrollHeight,
      behavior,
    });
  }

  verifyAcceptance = () => {
    if (this.accepted || !this.hasPendingNoticeTarget) return;

    const userMessageCount = this.chatMessageTargets.filter((chatMsg) => {
      return chatMsg.className === `chat-message msg-user_${this.userId}`;
    }).length;

    const otherMessageCount = this.chatMessageTargets.length - userMessageCount;

    if (otherMessageCount === 0 && userMessageCount === 0) return;
    if (otherMessageCount === 0 && userMessageCount >= 1)
      return this.showPendingNotice();

    this.accepted = true;
    this.removePendingNotice();
  };

  showPendingNotice() {
    this.pendingNoticeTarget.classList.remove("hidden");
    this.threadTarget.appendChild(this.pendingNoticeTarget);
  }

  removePendingNotice() {
    this.pendingNoticeTarget.classList.add("hidden");
  }

  markChatRead(node) {
    const senderId = node.getAttribute("data-sender-id");
    const markReadPath = this.data.get("mark-read-path");
    const isNotNewMessage = node.getAttribute("data-new-message") === "false";

    if (
      senderId === this.userId ||
      isHidden(node) ||
      this.hasDisclaimerTarget ||
      isNotNewMessage
    )
      return;
    const headers = { "X-CSRF-Token": this.data.get("token") };

    fetch(markReadPath, { method: "PATCH", headers }).then((res) => {
      if (!res.ok) {
        console.error("Error marking chat as read");
      }
    });
  }

  // Let the notification controller know what chat is being viewed
  updateNotifiableId() {
    // Actual chat messages might not be in view if viewing from mobile, return if so
    if (isHidden(this.element)) return;

    const event = new CustomEvent("updateNotifiableId", {
      detail: this.chatId,
    });
    window.dispatchEvent(event);
  }

  // Let the notification controller know that the chat is no longer being viewed
  clearNotifiableId() {
    const event = new CustomEvent("clearNotifiableId");
    window.dispatchEvent(event);
  }

  disconnect() {
    this.clearNotifiableId();
    this.observer.disconnect();
  }
}
