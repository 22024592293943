import { Controller } from "@hotwired/stimulus";
import { findElement, flashNow, simpleFormatAutoLink } from "../helpers";
import consumer from "../channels/consumer";

export default class extends Controller {
  static targets = [
    "submit",
    "textarea",
    "tempId",
    "typingContainer",
    "typingUsers",
  ];

  static values = {
    avatarPath: String,
    messageableType: String,
    messageableId: String,
    profilePath: String,
    senderId: String,
  };

  connect() {
    this.messagesTarget = findElement("#messages-scroll");
    this.initialHeight = this.textareaTarget.offsetHeight;
    this.messagePage = findElement("#chat-page-1");
    this.typingSubscription = this.subscribeToTypingChannel();
    this.typingTimeout = null;
    this.usersTyping = {};
    this.addEventListeners();
    this.reset({ focus: false });
    this.element.setAttribute("data-new-message-initialized", true);
  }

  addEventListeners() {
    this.element.addEventListener("turbo:submit-start", this.onSubmitStart);
    this.textareaTarget.addEventListener("input", this.onChange);
    this.textareaTarget.addEventListener("keydown", this.onKeydown);
    this.submitTarget.addEventListener("click", this.onSubmitClick);
  }

  subscribeToTypingChannel() {
    return consumer.subscriptions.create(
      {
        channel: "TypingChannel",
        messageable_type: this.messageableTypeValue,
        messageable_id: this.messageableIdValue,
      },
      {
        connected: this.typingChannelConnected,
        disconnected: this.typingChannelDisconnected,
        received: this.typingChannelReceived,
      }
    );
  }

  typingChannelConnected = () => {
    this.typingContainerTarget.setAttribute("data-channel-connected", true);
  };

  typingChannelDisconnected = () => {
    this.typingContainerTarget.setAttribute("data-channel-connected", false);
  };

  typingChannelReceived = (data) => {
    const { display_name, account_id, stopped_typing = false } = data;

    if (stopped_typing) {
      this.removeUserTyping(account_id);
    } else {
      this.addUserTyping(account_id, display_name);
    }

    this.updateTypingIndicator();
  };

  removeUserTyping = (accountId) => {
    const updatedUsers = { ...this.usersTyping };
    delete updatedUsers[accountId];
    this.usersTyping = updatedUsers;
  };

  addUserTyping = (accountId, displayName) => {
    if (accountId === this.senderIdValue) return;

    const newUser = { [accountId]: displayName };
    this.usersTyping = { ...this.usersTyping, ...newUser };
  };

  updateTypingIndicator = () => {
    const namesTyping = Object.values(this.usersTyping);
    const usersAreTyping = namesTyping.length > 0;

    this.typingContainerTarget.classList.toggle("active", usersAreTyping);
    this.typingUsersTarget.textContent = this.buildTypingUsers(namesTyping);
  };

  buildTypingUsers = (namesTyping) => {
    const namesTypingCount = namesTyping.length;

    const firstXnames = (numberOfNames) => {
      return namesTyping.slice(0, numberOfNames).join(", ");
    };

    const lastName = () => {
      return namesTyping[namesTypingCount - 1];
    };

    switch (namesTypingCount) {
      case 0:
        return "";
      case 1:
        return `${namesTyping[0]} is typing...`;
      case 2:
        return `${namesTyping.join(" and ")} are typing...`;
      case 3:
        return `${firstXnames(2)}, and ${lastName()} are typing...`;
      case 4:
        return `${firstXnames(3)}, and 1 other are typing...`;
      default:
        const remainingCount = namesTypingCount - 3;
        return `${firstXnames(3)}, and ${remainingCount} others are typing...`;
    }
  };

  clearTypingTimeout() {
    if (!this.typingTimeout) return;

    clearTimeout(this.typingTimeout);
    this.typingTimeout = null;
  }

  broadcastStopTyping = () => {
    this.typingSubscription.perform("stop_typing");
    this.clearTypingTimeout();
    this.typingContainerTarget.setAttribute("data-typing", false);
  };

  currentTempId() {
    return this.tempIdTarget.value;
  }

  reset(options = { focus: true }) {
    this.tempIdTarget.value = crypto.randomUUID(); // Generate a UUID to reference in turbo streams
    this.textareaTarget.value = "";
    this.textareaTarget.dispatchEvent(new Event("input", { bubbles: true }));
    this.textareaTarget.style.height = this.initialHeight + "px";

    if (options.focus) {
      this.textareaTarget.focus();
    }
  }

  broadcastTyping() {
    this.clearTypingTimeout();
    this.typingTimeout = setTimeout(this.broadcastStopTyping, 3000);
    this.typingSubscription.perform("typing");
    this.typingContainerTarget.setAttribute("data-typing", true);
  }

  onChange = (event) => {
    this.autoResize();
    const trimmedValue = event.target.value.trim();
    const inputIsBlank = trimmedValue === "";
    this.submitTarget.disabled = inputIsBlank;

    if (inputIsBlank) {
      this.broadcastStopTyping();
      return;
    }

    this.broadcastTyping();
  };

  onSubmitStart = (event) => {
    if (!navigator.onLine) {
      event.detail.formSubmission.stop();
      flashNow("alert", "You are offline. Please check your connection.");
      return;
    }

    if (this.textareaTarget.value.trim() === "") {
      event.detail.formSubmission.stop();
      return;
    }

    this.currentSentTempId = this.currentTempId();
    this.prependDateHeading();
    this.prependMessage();
    this.updateChatPreview();
    this.reset();
    this.handleScroll();
    this.submitTarget.disabled = true;
  };

  onSubmitClick = (event) => {
    event.preventDefault();
    this.element.requestSubmit();
  };

  // Prepend "Today" to the chat page if it doesn't exist
  prependDateHeading() {
    const dateString = new Date().toLocaleDateString("en-CA"); // 'YYYY-MM-DD'
    let dateHeading = document.getElementById(`chat-${dateString}`); // e.g. `chat-2021-01-01

    if (dateHeading) return;

    dateHeading = document.createElement("turbo-frame");
    dateHeading.className = "chat-message-date";
    dateHeading.id = `chat-${dateString}`;
    dateHeading.textContent = "Today";

    this.messagePage.prepend(dateHeading);
  }

  buildAvatar() {
    const avatarPath = this.avatarPathValue;
    const profilePath = this.profilePathValue;

    const link = document.createElement("a");
    link.href = profilePath;
    link.setAttribute("data-turbo-frame", "global-modal");
    link.classList.add("chat-message__avatar");

    const image = document.createElement("img");
    image.src = avatarPath;

    link.append(image);

    return link;
  }

  buildChatBubble() {
    let formattedTime = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    // Remove leading zeros
    formattedTime = formattedTime.replace(/^0+/, "");

    const chatBubble = document.createElement("div");
    chatBubble.className = "chat-message__bubble";

    const messageContent = document.createElement("div");
    messageContent.className = "message-content";

    const inputValue = this.textareaTarget.value;
    const messageContentHtml = simpleFormatAutoLink(inputValue);

    messageContent.innerHTML = messageContentHtml;

    const dateSpan = document.createElement("span");
    dateSpan.className = "chat-message__bubble__time";
    dateSpan.innerHTML = formattedTime;

    chatBubble.append(messageContent);
    chatBubble.append(dateSpan);

    return chatBubble;
  }

  // Build a preview message to display before the message is sent
  // See app/views/shared/chats/_message.html.erb
  buildMessage() {
    const senderId = this.senderIdValue;

    const messageContainer = document.createElement("turbo-frame");
    messageContainer.className = `chat-message msg-user_${senderId}`;
    messageContainer.setAttribute("data-sender-id", senderId);
    messageContainer.setAttribute("data-preview", true);
    messageContainer.id = this.currentTempId();

    const avatar = this.buildAvatar();
    messageContainer.append(avatar);

    const chatBubble = this.buildChatBubble();
    messageContainer.append(chatBubble);

    return messageContainer;
  }

  prependMessage() {
    const message = this.buildMessage();
    this.messagePage.prepend(message);
  }

  // Update the chat preview with the latest message, if a chat ID is present
  updateChatPreview() {
    if (this.messageableTypeValue !== "Chat") return;

    const chatId = this.messageableIdValue;
    const preview = document.getElementById(`chat-preview_${chatId}`);
    preview.innerText = this.textareaTarget.value;

    const chatCard = document.getElementById(chatId);
    const chatList = document.getElementById("chats");
    chatList.prepend(chatCard);
  }

  onKeydown = (event) => {
    if (event.code === "Enter" && (event.metaKey || event.ctrlKey)) {
      this.element.requestSubmit();
    }
  };

  autoResize() {
    if (this.textareaTarget.scrollHeight <= 60) {
      this.textareaTarget.style.height = this.initialHeight + "px";
    } else {
      this.textareaTarget.style.height = "auto";
      this.textareaTarget.style.height =
        this.textareaTarget.scrollHeight + "px";
    }
  }

  handleScroll() {
    this.messagesTarget.scroll({
      top: this.messagesTarget.scrollHeight,
      behavior: "smooth",
    });
  }

  disconnect() {
    this.element.removeEventListener("turbo:submit-start", this.onSubmitStart);
    this.textareaTarget.removeEventListener("input", this.onChange);
    this.textareaTarget.removeEventListener("keydown", this.onKeydown);
    this.submitTarget.removeEventListener("click", this.onSubmitClick);
  }
}
