import { Controller } from "@hotwired/stimulus";
import { findElement, deleteCookie } from "../helpers";
export default class extends Controller {
  static targets = [
    "continueBtn",
    "customGender",
    "radio",
    "checkbox",
    "requiredInput",
    "interestsUp",
    "interestsDown",
    "toggleShowBtn",
    "interestsContainer",
    "textarea",
    "charCount",
    "locationResult",
    "locationEdit",
    "locationSelect",
    "coordinates",
    "city",
    "state",
  ];

  initialize() {
    const section = this.data.get("section");

    switch (section) {
      case "credentials":
        this.validateRequiredInput();
        break;
      case "accountType":
        this.checkRadioSelection();
        break;
      case "userName":
        this.validateUserNameAndUpload();
        break;
      case "userDetails":
        this.validateUserDetails();
        break;
      case "whyHere":
        this.checkRadioSelection();
        break;
      case "interests":
        this.validateCheckboxSelection();
        this.interestsContainerTarget.addEventListener("scroll", () =>
          this.handleInterestControls()
        );
        break;
      case "aboutUser":
        this.validateTextarea();
        break;
      case "heardAbout":
      case "meetingPreferences":
        this.validateCheckboxSelection();
        break;
      case "orgName":
        this.validateRequiredInput();
        break;
      case "orgSize":
        this.checkRadioSelection();
        break;
      case "orgLocation":
        this.validateRequiredInput();
        break;
      case "consent":
        this.validateRequiredCheckboxes();
        break;
    }
  }

  enableContinue() {
    if (!this.continueBtnTarget.classList.contains("disabled")) return;
    this.continueBtnTarget.classList.remove("disabled");
  }

  disableContinue() {
    if (this.continueBtnTarget.classList.contains("disabled")) return;
    this.continueBtnTarget.classList.add("disabled");
  }

  checkRadioSelection() {
    const hasSelected = this.radioTargets.some((radio) => radio.checked);

    if (hasSelected) return this.enableContinue();
    this.disableContinue();
  }

  disableCustomGender() {
    this.customGenderTarget.name = "inactive";
    this.customGenderTarget.value = "";
  }

  enableCustomGender() {
    if (this.customGenderTarget.name === "user[gender]") return;
    this.customGenderTarget.name = "user[gender]";

    this.radioTargets.forEach((option) => {
      option.checked = false;
    });
  }

  imageIsUploaded() {
    return !!findElement(this.element, ".dz-complete");
  }

  requiredFieldsComplete() {
    return this.requiredInputTargets.every((input) => input.value.trim());
  }

  validateUserNameAndUpload() {
    if (this.imageIsUploaded() && this.requiredFieldsComplete()) {
      return this.enableContinue();
    }

    this.disableContinue();
  }

  validateUserDetails() {
    const inputEntered = this.requiredInputTargets.every((input) =>
      input.value.trim()
    );
    const genderSelected =
      this.radioTargets.some((radio) => radio.checked) ||
      this.customGenderTarget.value.trim();

    if (inputEntered && genderSelected) return this.enableContinue();
    this.disableContinue();
  }

  validateRequiredInput() {
    const formComplete = this.requiredInputTargets.every((input) =>
      input.value.trim()
    );

    if (formComplete) {
      this.enableContinue();
    } else {
      this.disableContinue();
    }
  }

  validateCheckboxSelection() {
    const minRequired = this.data.get("min") || 1;
    const selectedCount = this.checkboxTargets.filter(
      (option) => option.checked
    ).length;

    if (selectedCount >= minRequired) {
      return this.enableContinue();
    }
    this.disableContinue();
  }

  calcPages() {
    const offsetHeight = this.interestsContainerTarget.offsetHeight;
    const scrollHeight = this.interestsContainerTarget.scrollHeight;
    return Math.ceil(scrollHeight / offsetHeight);
  }

  calcPageHeight() {
    const breakPoint = 768;
    const labelHeight = 32;
    const gap = 16;
    let rows;
    if (window.innerWidth < breakPoint) {
      rows = 5;
    } else {
      rows = 4;
    }
    return (labelHeight + gap) * rows;
  }

  getCurrentPage() {
    return Math.ceil(
      this.interestsContainerTarget.scrollTop / this.calcPageHeight()
    );
  }

  showNext() {
    const nextPagePosition =
      (this.getCurrentPage() + 1) * this.calcPageHeight();
    this.interestsContainerTarget.scroll({
      top: nextPagePosition,
      behavior: "smooth",
    });
  }

  showPrev() {
    let prevPagePosition;
    if (
      this.interestsContainerTarget.scrollTop / this.calcPageHeight() >
      this.calcPages()
    ) {
      prevPagePosition = this.getCurrentPage() * this.calcPageHeight();
    } else {
      prevPagePosition = (this.getCurrentPage() - 1) * this.calcPageHeight();
    }
    this.interestsContainerTarget.scroll({
      top: prevPagePosition,
      behavior: "smooth",
    });
  }

  handleInterestControls() {
    if (this.interestsContainerTarget.scrollTop < 16) {
      this.interestsUpTarget.classList.add("hidden");
    } else {
      this.interestsUpTarget.classList.remove("hidden");
    }

    if (
      this.interestsContainerTarget.scrollTop >
      this.interestsContainerTarget.scrollHeight - this.calcPageHeight() - 20
    ) {
      this.interestsDownTarget.classList.add("hidden");
    } else {
      this.interestsDownTarget.classList.remove("hidden");
    }
  }

  validateTextarea() {
    const minLength = this.textareaTarget.minLength;
    const maxLength = this.textareaTarget.maxLength;
    const inputLength = this.textareaTarget.value.length;

    this.charCountTarget.textContent = `${inputLength}/${maxLength}`;

    if (inputLength === maxLength) {
      this.charCountTarget.className = "max";
    } else if (inputLength > maxLength * 0.95) {
      this.charCountTarget.className = "warning";
    } else {
      this.charCountTarget.className = "";
    }

    if (!this.hasContinueBtnTarget) return;

    if (inputLength >= minLength) {
      this.enableContinue();
    } else {
      this.disableContinue();
    }
  }

  showLocationEdit() {
    this.locationResultTarget.classList.add("hidden");
    this.locationEditTarget.classList.remove("hidden");
    const searchSelect = document.querySelector("#location-ts-control");
    searchSelect.addEventListener("blur", () => this.hideLocationEdit());
    setTimeout(() => {
      searchSelect.focus();
    }, 1);
  }

  hideLocationEdit() {
    if (
      document.querySelector("#location-ts-control").value === "" &&
      this.coordinatesTargets.every((input) => input.value === "")
    )
      return;
    setTimeout(() => {
      if (this.cityTarget.value !== "" && this.stateTarget.value !== "") return;
      this.locationEditTarget.classList.add("hidden");
      this.locationResultTarget.classList.remove("hidden");
    }, 1);
  }

  setLocation() {
    // Clear cookies to prevent user's location from automatically getting updated based off geolocation
    deleteCookie("latitude");
    deleteCookie("longitude");

    const location = this.locationSelectTarget.value.split(", ");
    this.coordinatesTargets.forEach((input) => (input.name = "inactive"));
    this.cityTarget.value = location[0];
    this.stateTarget.value = location[1];
  }

  validateNameAndUpload() {
    setTimeout(() => {
      const uploadPreview = findElement(this.element, ".dz-complete");
      if (uploadPreview && this.requiredInputTarget.value.trim()) {
        return this.enableContinue();
      }
      this.disableContinue();
    });
  }

  autoAbout() {
    const selectTarget =
      this.textareaTarget.getAttribute("data-select-target") || "";
    const autoCompleteValue =
      this.textareaTarget.getAttribute("data-auto-value") || "";
    const selectStart = autoCompleteValue.indexOf(selectTarget);
    const selectEnd = selectStart + selectTarget.length;

    this.textareaTarget.value = autoCompleteValue;
    this.textareaTarget.focus();
    this.textareaTarget.selectionStart = selectStart;
    this.textareaTarget.selectionEnd = selectEnd;
    this.validateTextarea();
  }

  validateRequiredCheckboxes() {
    const allChecked = this.checkboxTargets.every(
      (checkbox) => checkbox.checked
    );

    if (allChecked) {
      return this.enableContinue();
    }

    this.disableContinue();
  }
}
