import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "button"];

  initialize() {
    this.initialBtnText = this.buttonTarget.textContent;
    this.successBtnText = this.buttonTarget.getAttribute("data-after-text");
  }

  copy() {
    this.inputTarget.focus();
    this.inputTarget.select();
    this.inputTarget.setSelectionRange(0, 99999); // For mobile devices

    navigator.clipboard.writeText(this.inputTarget.value);

    this.showSuccess();
  }

  showSuccess() {
    if (!this.successBtnText) return;
    this.buttonTarget.textContent = this.successBtnText;
    setTimeout(() => this.resetBtnText(), 5000);
  }

  resetBtnText() {
    this.buttonTarget.textContent = this.initialBtnText;
  }
}
