import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trigger"];

  initialize() {
    const options = {
      root: document,
      rootMargin: this.data.get("rootMargin") || "0px",
      threshold: parseFloat(this.data.get("threshold") || 0.2),
    };

    this.observer = new IntersectionObserver(
      this.handleIntersection.bind(this),
      options
    );

    this.triggerTargets.forEach((trigger) => {
      this.observer.observe(trigger);
    });
  }

  handleIntersection(entries) {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;

      this.element.src = this.data.get("src");
      this.observer.unobserve(entry.target);
      this.observer.disconnect();
    });
  }

  disconnect() {
    if (this.observer) this.observer.disconnect();
  }
}
