import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["elem", "initial", "new"];

  fetchContent(e) {
    const path = e.target.dataset.path;

    fetch(path)
      .then((response) => response.text())
      .then((html) => (this.elemTarget.innerHTML = html));
  }

  update(event) {
    const options = event.target.dataset.options.split(",");
    this.elemTarget.innerHTML = options[event.target.value];
  }

  swap() {
    this.hideTarget(this.initialTarget);
    this.revealTarget(this.newTarget);
  }

  revert() {
    this.hideTarget(this.newTarget);
    this.revealTarget(this.initialTarget);
  }

  hideTarget(target) {
    target.classList.add("hidden");
  }

  revealTarget(target) {
    target.classList.remove("hidden");
  }
}
