import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static targets = ["trigger", "content"];

  initialize() {
    // Pass true to data-popup-authorized-value to disable tooltip and enable link
    if (this.data.get("authorized-value") === "true") {
      return;
    }

    // Disable clicking on the card
    this.triggerTarget.setAttribute("href", "#");
    this.triggerTarget.setAttribute("onclick", "return false;");

    this.trigger = this.getTrigger();
    this.content = this.getContent();

    this.initPopup();

    // Hide tooltip content on load
    this.content.style.display = "none";
  }

  initPopup() {
    const theme = this.data.get("theme");

    // Pass data-popup-timeout to hide after x ms
    const timeout = this.data.get("timeout")
      ? parseInt(this.data.get("timeout"))
      : null;

    // Pass data-popup-type if tooltip should be appended to specific element
    let appendTo;
    switch (this.data.get("type")) {
      case "profile-hub":
        appendTo = this.element.parentElement.parentElement;
        break;
      default:
        appendTo = () => document.body;
    }

    // Pass data-popup-trigger to specify event to show tip (mouseenter, etc)
    const trigger = this.data.get("trigger") || "click";
    const placement = this.data.get("placement") || "top";

    // Initiate tippy instance
    this.popup = tippy(this.trigger, {
      content: this.content.innerHTML,
      allowHTML: true,
      theme,
      placement,
      trigger,
      appendTo,
      onShow(instance) {
        if (!timeout) return;
        setTimeout(() => instance.hide(1000), timeout);
      },
    });
  }

  // Set tooltip content to contentTarget or value passed to data-popup-content
  getContent() {
    if (this.hasContentTarget) {
      return this.contentTarget;
    } else {
      var content = document.createElement("div");
      content.innerHTML = this.data.get("content");

      return content;
    }
  }

  getTrigger() {
    if (this.hasTriggerTarget) {
      return this.triggerTarget;
    } else {
      return this.element;
    }
  }
}
