import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  hideModal() {
    const element = this.findFrame(this.element);

    // Without timeout Turbo breaks
    setTimeout(() => {
      element.innerHTML = "";
    }, 1);
  }

  findFrame(element) {
    if (element.tagName === "TURBO-FRAME") {
      return element;
    }

    return this.findFrame(element.parentElement);
  }
}
