import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["button", "editable", "input"];

  connect() {
    this.editEnabled = false;
    this.editableClass = this.data.get("class");
    this.saveBtnText = this.buttonTarget.getAttribute("data-after-text");
    this.initialBtnText = this.buttonTarget.textContent;
    this.buttonTarget.type = "button";
  }

  toggleEdit(e) {
    if (e.target.type === "submit") return;
    this.editEnabled = !this.editEnabled;
    if (this.editEnabled) return this.enableEdit();
    this.disableEdit();
  }

  enableEdit() {
    this.editEnabled = true;
    this.editableTarget.classList.add(this.editableClass);
    this.buttonTarget.classList.add("active");
    this.buttonTarget.textContent = this.saveBtnText;
  }

  disableEdit() {
    this.editEnabled = false;
    this.editableTarget.classList.remove(this.editableClass);
    this.buttonTarget.classList.remove("active");
    this.buttonTarget.type = "button";
    this.buttonTarget.textContent = this.initialBtnText;
  }

  // Only allow submit if a target has been selected
  verifyMarked() {
    setTimeout(() => {
      if (this.inputTargets.some((input) => input.disabled !== true)) {
        this.buttonTarget.type = "submit";
      } else {
        this.buttonTarget.type = "button";
      }
    });
  }
}
