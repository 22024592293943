import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "startTimeContainer",
    "startDateInput",
    "startTimeInput",
    "endTimeContainer",
    "endDateInput",
    "endTimeInput",
    "eventTypeRadio",
    "meetingUrlContainer",
    "meetingUrlInput",
    "locationContainer",
    "locationInput",
    "customInstructionsContainer",
  ];

  initialize() {
    this.showEndTime =
      !this.endTimeContainerTarget.classList.contains("hidden");
    this.showLocation =
      !this.locationContainerTarget.classList.contains("hidden");
    this.showCustomInstructions =
      !this.customInstructionsContainerTarget.classList.contains("hidden");
  }

  // Shared
  clearErrors(containerElement) {
    const errors = containerElement.querySelectorAll(".error");
    errors.forEach((error) => error.remove());
  }

  // Start and end time
  toggleEndTime(e) {
    const toggleButton = e.currentTarget;
    const newBeforeText =
      toggleButton.getAttribute("data-before-text") === "+" ? "-" : "+";

    this.showEndTime = !this.showEndTime;
    this.endTimeContainerTarget.classList.toggle("hidden");
    toggleButton.setAttribute("data-before-text", newBeforeText);

    if (this.showEndTime) {
      this.setEndTime();
    } else {
      this.clearEndTime();
      this.clearErrors(this.endTimeContainerTarget);
    }

    this.fireRedrawEvent();
  }

  setEndTime() {
    const startDate = this.startDateInputTarget.value;
    const startTime = this.startTimeInputTarget.value;
    const newDateTimeInSeconds = this.calculateEndTime(startDate, startTime);

    this.endDateInputTarget.value =
      this.secondsToDateString(newDateTimeInSeconds);
    this.endTimeInputTarget.value =
      this.secondsToTimeString(newDateTimeInSeconds);
  }

  calculateEndTime(startDateString, startTimeString, durationInMinutes = 60) {
    const dateTimeInSeconds = new Date(
      `${startDateString} ${startTimeString} UTC`
    ).getTime();
    return dateTimeInSeconds + durationInMinutes * 60 * 1000; // 60 minutes * 60 seconds * 1000 milliseconds = 1 hour
  }

  secondsToDateString(seconds) {
    const newDate = new Date(seconds);
    return newDate.toISOString().split("T")[0];
  }

  secondsToTimeString(seconds) {
    const newDate = new Date(seconds);
    return newDate.toISOString().split("T")[1].split(":").slice(0, 2).join(":");
  }

  clearEndTime() {
    this.endDateInputTarget.value = this.startDateInputTarget.value;
    this.endTimeInputTarget.value = this.startTimeInputTarget.value;
  }

  fireRedrawEvent() {
    const event = new Event("redraw-flatpickr");
    window.dispatchEvent(event);
  }

  // Event type
  toggleEventType() {
    this.showLocation = !this.showLocation;
    this.locationContainerTarget.classList.toggle("hidden");
    this.meetingUrlContainerTarget.classList.toggle("hidden");

    if (this.showLocation) {
      this.meetingUrlInputTarget.value = "";
      this.clearErrors(this.meetingUrlContainerTarget);
    } else {
      this.locationInputTarget.value = "";
      this.clearErrors(this.locationContainerTarget);
    }
  }

  // Custom instructions
  toggleCustomInstructions() {
    this.showCustomInstructions = !this.showCustomInstructions;
    this.customInstructionsContainerTarget.classList.toggle("hidden");
  }
}
