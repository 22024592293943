import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.initialMessagesLoaded = false;

    this.element.addEventListener("turbo:frame-load", (e) =>
      this.onMessageFrameLoad(e)
    );
  }

  onMessageFrameLoad() {
    if (this.initialMessagesLoaded) return;

    this.initialMessagesLoaded = true;
    this.fireInfiniteFrameLoaded();
  }

  fireInfiniteFrameLoaded() {
    const event = new CustomEvent("infinite-frame:loaded");
    window.dispatchEvent(event);
  }
}
