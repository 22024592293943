import { Controller } from "@hotwired/stimulus";
import mapboxgl from "mapbox-gl";

export default class extends Controller {
  static targets = ["map"];

  connect() {
    this.center = JSON.parse(this.data.get("center") || "[2.3522, 48.8566]");
    this.zoom = parseInt(this.data.get("zoom") || "12");
    this.markers = this.parseRawMarkerData();
    this.map = null;
    this.buildMap();
  }

  parseRawMarkerData() {
    const rawMarkers = this.data.get("markers");

    // If there are no markers, return an empty array
    if (!rawMarkers) return [];

    const parsedMarkers = JSON.parse(rawMarkers);

    // If there is an array of markers, return it
    if (Array.isArray(parsedMarkers) && Array.isArray(parsedMarkers[0])) return parsedMarkers;

    // If there is a single marker, return it in an array
    return [parsedMarkers];
  }

  buildMap() {
    if (!this.hasMapTarget) return;

    mapboxgl.accessToken = this.data.get("token");
    this.map = new mapboxgl.Map({
      container: this.mapTarget,
      style: "mapbox://styles/mapbox/streets-v11",
      center: this.center,
      zoom: this.zoom,
    });

    this.markers.forEach((marker) => {
      this.addMarker(marker);
    });
  }

  addMarker(marker) {
    const element = document.createElement("div");
    element.className = "marker";

    new mapboxgl.Marker(element).setLngLat([marker.lng, marker.lat]).setPopup(this.buildPopup(marker)).addTo(this.map);
  }

  buildPopup(marker) {
    const element = document.createElement("div");
    const strong = document.createElement("strong");
    strong.innerText = marker.name;
    element.appendChild(strong);
    const span = document.createElement("span");
    span.innerText = marker.location;
    element.appendChild(span);

    return new mapboxgl.Popup({ offset: 30 }).setDOMContent(element);
  }
}
