import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    maxDisplayedUsers: Number, // Maximum number of tagged users to display on the activity form
  };

  static targets = [
    "count", // Span where number of tagged users is displayed on the activity form
    "destroyInput", // Hidden checkbox for each user when deleting in the tag members modal
    "hiddenInput", // Hidden checkbox for each user in the activity form, controlled by the selectableInput target
    "inputContainer", // Container where hidden inputs are displayed on the activity form
    "organizationSelect", // Select element for choosing an organization, only displayed if using the "New Activity" button
    "previewContainer", // Container where previews of tagged users are displayed on the activity form
    "preview", // Individual tagged user preview in the activity form
    "notShownCount", // Span that displays the number of tagged users that are not shown in the activity form
    "selectableInput", // Hidden checkbox for each user when tagging in the tag members modal
    "selectAllCheckbox", // Checkbox to select all users in the tag members modal
    "selectAllInput", // Hidden input for the select all checkbox in the tag members modal
    "selectExceptInput", // Hidden input that contains a comma-separated list of user IDs to exclude when in select all mode
    "taggableMembersCount", // Hidden input for the number of taggable members in the tag members modal based on the selected organization
    "tagMembersLink", // Link to open the tag members modal
  ];

  connect() {
    this.selectedUserIds = {};
    this.excludedUserIds = {};
    this.taggableMembersCount = 0;
    this.selectAll = false;
    this.setInitialSelectedUserIds();
  }

  hiddenInputTargetConnected(input) {
    const { value: userId } = input;
    this.selectedUserIds[userId] = true;
    this.updateCount();
  }

  hiddenInputTargetDisconnected(input) {
    const { value: userId } = input;
    this.selectedUserIds[userId] = false;
    this.updateCount();
  }

  organizationSelectTargetConnected(select) {
    const { value: organizationId } = select;
    this.updateTagMembersLink(organizationId);
  }

  taggableMembersCountTargetConnected(input) {
    const { value: count } = input;
    this.taggableMembersCount = parseInt(count);
  }

  selectAllCheckboxTargetConnected(checkbox) {
    this.updateSelectAllCheckbox();
  }

  // Don't show previews that are over the max displayed users value
  previewTargetConnected(preview) {
    const currentPreviewIndex = this.previewTargets.indexOf(preview);
    const hidden = currentPreviewIndex >= this.maxDisplayedUsersValue;
    preview.classList.toggle("hidden", hidden);
  }

  // Only show the "Not shown" count if there are more users than the max displayed users value
  notShownCountTargetConnected(span) {
    const maxPreviewCountReached = this.previewTargets.length >= this.maxDisplayedUsersValue;
    span.parentElement.classList.toggle("hidden", !maxPreviewCountReached);
  }

  // If a user is already tagged, make sure we check the checkbox in the tag members modal
  // If selectAll is true, check the checkbox unless the user is excluded
  selectableInputTargetConnected(input) {
    const { value: userId } = input;
    const includedInSelectAll = this.selectAll && !this.excludedUserIds[userId];
    input.checked = includedInSelectAll || this.selectedUserIds[userId];
  }

  // When the user changes the organization, set the tag members link and clear all tagged/excluded users
  onOrganizationChange(event) {
    const { value: organizationId } = event.target;
    this.updateTagMembersLink(organizationId);
    this.clearTaggedUsers();
    this.updateCount();
    this.selectAll = false;
  }

  updateTagMembersLink(organizationId) {
    const blankOrganizationId = organizationId === "";
    this.tagMembersLinkTarget.classList.toggle("disabled", blankOrganizationId);

    const { href } = this.tagMembersLinkTarget;

    const url = new URL(href);
    url.searchParams.set("organization_id", organizationId);

    this.tagMembersLinkTarget.href = url.toString();
  }

  // Remove previews and hidden inputs for all tagged users
  clearTaggedUsers() {
    this.selectedUserIds = {};
    this.previewTargets.forEach((preview) => preview.remove());
    this.hiddenInputTargets.forEach((input) => input.remove());
  }

  setInitialSelectedUserIds() {
    this.destroyInputTargets.forEach((input) => {
      const userId = input.dataset.userId;
      this.selectedUserIds[userId] = true;
    });
  }

  // Sets the value of the selectExceptInput to a comma-separated list of user IDs to exclude
  // Based on the excludedUserIds object
  updateSelectExceptInputValue() {
    const excludedUserIds = Object.entries(this.excludedUserIds)
      .filter(([, excluded]) => excluded)
      .map(([userId]) => userId);
    const stringIds = excludedUserIds.join(",");
    this.selectExceptInputTarget.value = stringIds;
  }

  // Returns the number of users that are not marked for destruction
  inactiveDestroyInputCount() {
    return this.destroyInputTargets.filter((input) => input.value !== "1").length;
  }

  // If selectAll is enabled, we don't want to check the checkbox if any users are excluded
  updateSelectAllCheckbox() {
    const excludedUserIdsCount = this.excludedUserIdsCount();
    const destroyInputCount = this.inactiveDestroyInputCount();
    const noExcludedUsers = this.selectAll && excludedUserIdsCount === 0;
    const noUsersMarkedForDestruction = destroyInputCount === this.taggableMembersCount;
    const allUsersSelected = this.selectedUserIdsCount === this.taggableMembersCount;
    const checked = noExcludedUsers || noUsersMarkedForDestruction || allUsersSelected;
    this.selectAllCheckboxTarget.checked = checked;
  }

  // Fired when the user clicks on a user in the tag members modal with selectAll disabled
  toggleOptionWithoutSelectAll(event) {
    const { checked, value: userId } = event.target;
    this.selectedUserIds[userId] = checked;

    if (checked) {
      this.appendPreview(event.target);
      this.appendHiddenInput(event.target);
    } else {
      this.removePreview(userId);
      this.removeHiddenInput(userId);
    }
  }

  // Fired when the user clicks on a user in the tag members modal with selectAll enabled
  toggleOptionWithSelectAll(event) {
    const { checked, value: userId } = event.target;
    this.excludedUserIds[userId] = !checked;

    if (checked) {
      this.appendPreview(event.target);
    } else {
      this.removePreview(userId);
    }

    this.updateSelectExceptInputValue();
    this.updateSelectAllCheckbox();
    this.updateCount();
  }

  // Fired when the user clicks on a user in the tag members modal
  toggleOption(event) {
    if (this.selectAll) {
      this.toggleOptionWithSelectAll(event);
    } else {
      this.toggleOptionWithoutSelectAll(event);
    }
  }

  // Fired when the user clicks on the "Select All" checkbox in the tag members modal
  toggleSelectAll(event) {
    const { checked } = event.target;
    this.selectAll = checked;
    this.selectAllInputTarget.value = checked ? "1" : "0";

    // Clear all tagged users and excluded users
    this.clearTaggedUsers();
    this.excludedUserIds = {};
    this.updateSelectExceptInputValue();

    // Update the currently visible options
    this.toggleSelectAllVisibleOptions();

    // For persisted activities, we need to update the destroy inputs
    this.destroyInputTargets.forEach((input) => {
      input.value = checked ? "0" : "1";
    });

    // Remove all previews if selectAll is disabled
    if (!checked) {
      this.previewTargets.forEach((preview) => preview.remove());
    }

    // Update the count
    const newCount = checked ? this.taggableMembersCount : 0;
    this.setCount(newCount);
  }

  toggleSelectAllVisibleOptions() {
    this.selectableInputTargets.forEach((input) => {
      input.checked = this.selectAll;
      const { value: userId } = input;

      if (!this.selectAll) {
        return;
      }

      this.appendPreview(input);
    });
  }

  userDestroyInputElement(userId) {
    return this.destroyInputTargets.find((input) => input.dataset.userId === userId);
  }

  buildPreviewDiv(userId) {
    const div = document.createElement("div");

    div.classList.add("stacked-item");
    div.setAttribute("data-tag-activity-users-target", "preview");
    div.setAttribute("data-user-id", userId);

    return div;
  }

  buildPreviewImg(avatarUrl) {
    const img = document.createElement("img");

    img.src = avatarUrl;

    return img;
  }

  buildPreview(checkedInput) {
    const { value: userId } = checkedInput;
    const { avatarUrl } = checkedInput.dataset;

    const div = this.buildPreviewDiv(userId);
    const img = this.buildPreviewImg(avatarUrl);

    div.appendChild(img);

    return div;
  }

  appendPreview(checkedInput) {
    const preview = this.buildPreview(checkedInput);
    this.previewContainerTarget.appendChild(preview);

    const destroyInput = this.userDestroyInputElement(checkedInput.value);

    if (destroyInput) {
      destroyInput.value = "0";
      this.selectedUserIds[checkedInput.value] = true;
      this.updateCount();
    }

    // Move the "Not shown" count to the end of the preview container
    this.previewContainerTarget.appendChild(this.notShownCountTarget.parentElement);
  }

  removePreview(userId) {
    const preview = this.previewTargets.find((option) => option.dataset.userId === userId);

    if (preview) {
      preview.remove();
    }

    const hiddenInput = this.hiddenInputTargets.find((option) => option.value === userId);

    if (hiddenInput) {
      hiddenInput.remove();
    }

    const destroyInput = this.userDestroyInputElement(userId);

    if (destroyInput) {
      destroyInput.value = "1";
      this.selectedUserIds[userId] = false;
      this.updateCount();
    }
  }

  buildHiddenInput(userId) {
    const input = document.createElement("input");
    const index = this.hiddenInputTargets.length + this.destroyInputTargets.length;

    input.value = userId;
    input.type = "hidden";
    input.name = `activity[tagged_activity_users_attributes][${index}][user_id]`;
    input.setAttribute("data-tag-activity-users-target", "hiddenInput");

    return input;
  }

  appendHiddenInput(checkedInput) {
    const { value: userId } = checkedInput;
    const destroyInput = this.userDestroyInputElement(userId);

    if (destroyInput) {
      return;
    }

    const input = this.buildHiddenInput(userId);
    this.inputContainerTarget.appendChild(input);
  }

  removeHiddenInput(userId) {
    const input = this.hiddenInputTargets.find((option) => option.value === userId);

    if (!input) {
      return;
    }

    input.remove();
  }

  truthyValueCount(object) {
    return Object.values(object).filter(Boolean).length;
  }

  selectedUserIdsCount() {
    return this.truthyValueCount(this.selectedUserIds);
  }

  excludedUserIdsCount() {
    return this.truthyValueCount(this.excludedUserIds);
  }

  pluralize(count) {
    return count === 1 ? "member" : "members";
  }

  setCount(count) {
    const remainingCount = count - this.maxDisplayedUsersValue;
    this.notShownCountTarget.textContent = remainingCount > 0 ? remainingCount : "";
    this.notShownCountTarget.parentElement.classList.toggle("hidden", remainingCount <= 0);

    const pluralize = this.pluralize(count);
    const text = `${count} ${pluralize} tagged`;
    this.countTarget.textContent = count === 0 ? "" : text;
  }

  updateCountWithoutSelectAll() {
    const count = this.selectedUserIdsCount();
    this.setCount(count);
  }

  updateCountWithSelectAll() {
    const excludedCount = this.excludedUserIdsCount();
    const count = this.taggableMembersCount - excludedCount;
    this.setCount(count);
  }

  updateCount() {
    if (this.selectAll) {
      this.updateCountWithSelectAll();
    } else {
      this.updateCountWithoutSelectAll();
    }
  }
}
