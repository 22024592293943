import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";
import { findElement } from "../helpers";
import { SCREEN_MD } from "../breakpoints";

export default class extends Controller {
  static targets = ["form", "input", "results", "loader", "row"];

  initialize() {
    const delay = this.data.get("delay") || 300;
    this.handleInput = debounce(this.handleInput, delay).bind(this);
    this.globalModalTarget = findElement("#global-modal");
  }

  handleInput(e) {
    const inputValue = e.target.value.trim();
    if (inputValue !== "") {
      this.formTarget.requestSubmit();
    } else {
      this.clearResults();
    }
  }

  setLoading() {
    this.loaderTarget.classList.add("active");
  }

  clearLoading() {
    this.loaderTarget.classList.remove("active");
  }

  clearResults() {
    if (!this.hasResultsTarget) return;
    this.resultsTarget.remove();
  }

  // Fired after completing dropdown action, remove results container if no rows remaining
  refresh() {
    setTimeout(() => {
      if (this.hasRowTarget) return;
      this.clearResults();
    }, 50);
  }

  handleClick(e) {
    // Allow user to open and close modals from result list without clearing results
    if (
      !this.hasResultsTarget ||
      this.resultsTarget.contains(e.target) ||
      (this.globalModalTarget.contains(e.target) &&
        !this.globalModalTarget.contains(this.element)) ||
      window.innerWidth < SCREEN_MD ||
      this.inputTarget === e.target
    )
      return;

    // Ignore keyboard clicks (iPad fix)
    if (e.clientX === 0 && e.clientY === 0) return;

    this.clearResults();
    this.inputTarget.value = "";
  }
}
