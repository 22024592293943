import { Controller } from "@hotwired/stimulus";
import { getCsrfToken } from "../helpers";
import flatpickr from "flatpickr";

export default class extends Controller {
  static values = {
    deletePath: String,
  };

  static targets = ["form", "startTime", "endTime", "errors"];

  connect() {
    this.startTimePicker = this.initializeFlatpickr(this.startTimeTarget);
    this.startTimePicker.altInput.setAttribute("data-field", "start-time");
    this.endTimePicker = this.initializeFlatpickr(this.endTimeTarget);
    this.endTimePicker.altInput.setAttribute("data-field", "end-time");
    this.initialValues = this.getFlatpickrValues();
    this.formTarget.addEventListener("turbo:submit-end", this.submitEnd);
    this.fireTimeSlotsChangedEvent();
  }

  disconnect() {
    this.startTimePicker.destroy();
    this.endTimePicker.destroy();
    this.fireTimeSlotsChangedEvent();
  }

  initializeFlatpickr(element) {
    const options = {
      allowInput: true,
      clickOpens: true,
      altInput: true,
      altFormat: "h:i K",
      dateFormat: "H:i",
      altInputClass: "input-text",
      disableMobile: true,
      noCalendar: true,
      enableTime: true,
      onClose: this.onClose,
    };

    return flatpickr(element, options);
  }

  getFlatpickrValues() {
    return {
      startTime: this.startTimePicker.input.value,
      endTime: this.endTimePicker.input.value,
    };
  }

  valuesChanged() {
    const currentValues = this.getFlatpickrValues();
    return (
      currentValues.startTime !== this.initialValues.startTime ||
      currentValues.endTime !== this.initialValues.endTime
    );
  }

  submitAndResetValues() {
    this.formTarget.requestSubmit();
    this.initialValues = this.getFlatpickrValues();
  }

  onClose = (selectedDates, dateStr, instance) => {
    if (!this.valuesChanged()) {
      return;
    }

    this.submitAndResetValues();
  };

  submitEnd = (event) => {
    if (event.detail.success) {
      this.element.setAttribute("data-saved", "true");
      return;
    }

    this.renderError("Failed to save time slot");
  };

  turboDelete() {
    fetch(this.deletePathValue, {
      method: "DELETE",
      dataType: "script",
      credentials: "include",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": getCsrfToken(),
      },
    })
      .then((response) =>
        response.text().then((html) => Turbo.renderStreamMessage(html))
      )
      .catch((error) => {
        this.renderError("Failed to delete time slot");
      });
  }

  delete() {
    if (this.isPersisted()) {
      this.turboDelete();
      return;
    }

    this.element.remove();
  }

  isPersisted() {
    return this.deletePathValue !== "";
  }

  renderError(msg) {
    const p = document.createElement("p");
    p.textContent = msg;

    this.errorsTarget.innerHTML = "";
    this.errorsTarget.appendChild(p);
  }

  fireTimeSlotsChangedEvent() {
    const event = new CustomEvent("timeSlotsChanged", {
      detail: { day: this.element.dataset.day },
    });

    window.dispatchEvent(event);
  }
}
