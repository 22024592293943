import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.active = false;
    this.links = Array.from(this.element.querySelectorAll("a"));
    this.hideDropdown = this.hideDropdown.bind(this);
    this.links.forEach((link) => {
      link.addEventListener("click", this.hideDropdown);
    });
  }

  toggle() {
    if (this.active) {
      this.hideDropdown();
    } else {
      this.showDropdown();
    }
  }

  showDropdown() {
    this.active = true;
    this.element.classList.add("active");
  }

  hideDropdown() {
    this.active = false;
    this.element.classList.remove("active");
  }

  handleClick(e) {
    if ((e && this.element.contains(e.target)) || !this.active) return;
    this.hideDropdown();
  }

  disconnect() {
    this.links.forEach((link) => {
      link.removeEventListener("click", this.hideDropdown);
    });
  }
}
