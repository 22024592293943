import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { dataAttributeToBoolean } from "../helpers";

export default class extends Controller {
  initialize() {
    this.dataId = this.data.get("data-id") || null;

    const options = {
      allowInput: dataAttributeToBoolean(this, "disable-input") || true,
      clickOpens: dataAttributeToBoolean(this, "allow-click") || false,
      altInput: dataAttributeToBoolean(this, "disable-alt") || true,
      altFormat: this.data.get("alt-format") || "m/d/Y",
      dateFormat: this.data.get("date-format") || "Y-m-d",
      altInputClass: this.data.get("class") || "",
      disableMobile: dataAttributeToBoolean(this, "enable-mobile") || true,
      noCalendar: dataAttributeToBoolean(this, "no-calendar") || false,
      enableTime: dataAttributeToBoolean(this, "enable-time") || false,
    };

    this.flatpickr = flatpickr(this.element, options);
    this.setInputDataId();
  }

  redraw() {
    this.flatpickr.setDate(this.element.value);
    this.flatpickr.redraw();
  }

  destroy() {
    this.flatpickr.destroy();
  }

  setInputDataId() {
    if (!this.dataId) return;

    this.flatpickr.altInput.setAttribute("data-id", this.dataId);
    this.flatpickr.altInput.id = this.dataId;
  }
}
