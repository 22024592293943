import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "input"];

  initialize() {
    this.otherValue = this.data.get("otherValue") || "Other";
  }

  onSelect(event) {
    const selectedValue = event.target.value;
    const isOther =
      selectedValue.toLowerCase() === this.otherValue.toLowerCase();

    this.selectTarget.disabled = isOther;
    this.inputTarget.disabled = !isOther;
    this.inputTarget.classList.toggle("hidden", !isOther);

    if (!isOther) {
      return;
    }

    this.inputTarget.focus();
  }
}
