import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

export default class extends Controller {
  static targets = ["scrollArea", "step", "backButton", "nextButton"];

  initialize() {
    this.scrollAreaTarget.addEventListener(
      "scroll",
      throttle(() => this.handleScroll(), 250)
    );

    this.handleScroll();

    this.threshold = 150; // account for extra padding on the right
  }

  getStepWidth() {
    return this.stepTargets[0].offsetWidth;
  }

  next() {
    const newPosition = this.scrollAreaTarget.scrollLeft + this.getStepWidth();
    this.scrollTo(newPosition);
  }

  back() {
    const newPosition = this.scrollAreaTarget.scrollLeft - this.getStepWidth();
    this.scrollTo(newPosition);
  }

  scrollTo(newScrollLeft) {
    this.scrollAreaTarget.scroll({
      left: newScrollLeft,
      behavior: "smooth",
    });
  }

  handleScroll() {
    const scrollPos = this.scrollAreaTarget.scrollLeft;
    const scrollWidth = this.scrollAreaTarget.scrollWidth;
    const elementWidth = this.scrollAreaTarget.offsetWidth;

    this.backButtonTarget.disabled = scrollPos < this.getStepWidth();
    this.nextButtonTarget.disabled =
      scrollPos >= scrollWidth - elementWidth - this.threshold;
  }
}
