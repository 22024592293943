import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["switch"];

  submitStart() {
    this.switchTarget.disabled = true;
  }

  submitEnd() {
    this.switchTarget.disabled = false;
  }

  requestSubmit(e) {
    if (e.target.value === "") return;
    this.element.requestSubmit();
  }
}
