import { Controller } from "@hotwired/stimulus";
import Papa from "papaparse";

export default class extends Controller {
  static targets = [
    "checkbox",
    "error",
    "input",
    "inputContainer",
    "list",
    "listContainer",
    "selectedCount",
    "totalCount",
    "submit",
  ];

  browse() {
    this.inputTarget.click();
  }

  processFile() {
    const file = this.inputTarget.files[0];

    if (!file) {
      this.clearError();
      return;
    }

    if (this.isInvalidFile(file)) {
      this.resetInputWithError("Invalid file type. Please select a CSV file.");
      return;
    }

    this.parseFile(file);
  }

  isInvalidFile(file) {
    return file.type !== "text/csv";
  }

  parseFile(file) {
    Papa.parse(file, {
      complete: (results) => {
        const emails = this.extractEmailsFromCSV(results.data);
        emails.length === 0
          ? this.renderError("No valid email addresses found.")
          : this.displayEmails(emails);
      },
      header: false,
    });
  }

  extractEmailsFromCSV(data) {
    const emails = [];

    for (const row of data) {
      for (const cell of row) {
        const trimmed = cell.trim();
        if (this.validateEmail(trimmed) && !emails.includes(trimmed)) {
          emails.push(trimmed);
        }
      }
    }

    return emails;
  }

  validateEmail(email) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  displayEmails(emails) {
    this.listContainerTarget.classList.remove("hidden");
    this.inputContainerTarget.classList.add("hidden");
    this.totalCountTarget.textContent = emails.length;
    this.selectedCountTarget.textContent = emails.length;
    this.submitTarget.disabled = false;

    this.populateEmailList(emails.sort((a, b) => a.localeCompare(b)));
  }

  populateEmailList(sortedEmails) {
    this.listTarget.innerHTML = "";

    sortedEmails.forEach((email) => {
      const label = document.createElement("label");
      label.textContent = email;

      const input = this.buildLabelledInput(email);
      label.prepend(input);

      this.listTarget.appendChild(label);
    });
  }

  buildLabelledInput(email) {
    const input = document.createElement("input");
    input.setAttribute("type", "checkbox");
    input.setAttribute("name", "csv_emails[]");
    input.setAttribute("value", email);
    input.setAttribute("checked", true);
    input.setAttribute("data-csv-invite-target", "checkbox");
    input.setAttribute("data-action", "csv-invite#updateSelectedCount");

    return input;
  }

  updateSelectedCount() {
    const checkedCount = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked
    ).length;
    this.selectedCountTarget.textContent = checkedCount;
    this.submitTarget.disabled = checkedCount === 0;
  }

  renderError(message) {
    this.errorTarget.textContent = message;
    this.errorTarget.classList.remove("hidden");
  }

  clearError() {
    this.errorTarget.textContent = "";
    this.errorTarget.classList.add("hidden");
  }

  resetInputWithError(message) {
    this.inputTarget.value = "";
    this.renderError(message);
  }

  reset() {
    this.inputTarget.value = "";
    this.clearError();
    this.listContainerTarget.classList.add("hidden");
    this.listTarget.innerHTML = "";
    this.inputContainerTarget.classList.remove("hidden");
    this.submitTarget.disabled = true;
  }
}
