import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";
import { isScreenLg } from "../helpers";

export default class extends Controller {
  static targets = [
    "tab", // <button> elements in the header for Events and Chat
    "panel", // <div> elements for the Events and Chat panels
  ];

  connect() {
    const selectedTabButton = this.tabTargets.find(
      (tab) => tab.getAttribute("aria-selected") === "true"
    );

    this.selectedTab = selectedTabButton.dataset.tab;
    window.addEventListener("resize", throttle(this.onWindowResize, 100));
    this.onWindowResize();
  }

  toggle(event) {
    const { currentTarget: button } = event;
    this.selectedTab = button.dataset.tab;

    this.selectSelectedTab();
    this.showSelectedPanel();
  }

  onWindowResize = () => {
    if (isScreenLg()) {
      this.showAllPanels();
    } else {
      this.showSelectedPanel();
    }
  };

  showAllPanels() {
    this.panelTargets.forEach((panel) => {
      panel.setAttribute("aria-hidden", false);
    });
  }

  showSelectedPanel() {
    this.panelTargets.forEach((panel) => {
      const selected = panel.dataset.panel === this.selectedTab;
      panel.setAttribute("aria-hidden", !selected);
    });
  }

  selectSelectedTab() {
    this.tabTargets.forEach((tab) => {
      const selected = tab.dataset.tab === this.selectedTab;
      tab.setAttribute("aria-selected", selected);
    });
  }

  disconnect() {
    window.removeEventListener("resize", throttle(this.onWindowResize, 100));
  }
}
