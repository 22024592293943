import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.errorElement = this.buildError();
  }

  handleInput(e) {
    const maxLength = e.target.getAttribute("maxlength");
    const inputLength = e.target.value.length;

    if (inputLength >= maxLength) {
      this.renderError();
    } else {
      this.clearError();
    }
  }

  renderError() {
    this.element.prepend(this.errorElement);
  }

  clearError() {
    this.errorElement.remove();
  }

  buildError() {
    const div = document.createElement("div");
    div.className = `flash_message ${this.data.get("class")}`;
    div.setAttribute("data-controller", "removals");
    div.setAttribute(
      "data-action",
      "animationend->removals#remove click->removals#remove"
    );
    const span = document.createElement("span");
    span.textContent = "You've reached the maximum character length";
    span.className = `${this.data.get("class")}__message`;
    div.appendChild(span);

    return div;
  }
}
