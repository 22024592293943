import { Controller } from "@hotwired/stimulus";
import { findElement } from "../helpers";
let debounce = require("lodash/debounce");

export default class extends Controller {
  initialize() {
    const delay = this.data.get("delay") || 200;
    this.handleInput = debounce(this.handleInput, delay).bind(this);

    this.resultsSelector = this.data.get("results-selector") || null;
    if (!this.resultsSelector) return;

    this.resultsElement = findElement(this.resultsSelector);
    this.originalResults = this.resultsElement.innerHTML;
  }

  handleInput(e) {
    const minLength = this.data.get("min-length") || 0;
    const input = e.target.value.trim();
    if (input.length === 0 && this.resultsElement) return this.resetResults();

    if (
      this.data.get("ignore-delete") &&
      e.inputType === "deleteContentBackward"
    )
      return;

    if (input.length < minLength) return;

    this.element.requestSubmit();
  }

  resetResults() {
    this.resultsElement.innerHTML = this.originalResults;
  }
}
