import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    let interest_list_1_next = document.getElementById("interest_list_1_next");
    let interest_list_2_back = document.getElementById("interest_list_2_back");
    let interest_list_2_next = document.getElementById("interest_list_2_next");
    let interest_list_3_back = document.getElementById("interest_list_3_back");

    interest_list_1_next.addEventListener("click", function handleClick() {
      document.getElementById("interest_list_1").classList.add("hidden");
      document.getElementById("interest_list_2").classList.remove("hidden");
      document.getElementById("interest_list_3").classList.add("hidden");
    });

    interest_list_2_back.addEventListener("click", function handleClick() {
      document.getElementById("interest_list_1").classList.remove("hidden");
      document.getElementById("interest_list_2").classList.add("hidden");
      document.getElementById("interest_list_3").classList.add("hidden");
    });

    interest_list_2_next.addEventListener("click", function handleClick() {
      document.getElementById("interest_list_2").classList.add("hidden");
      document.getElementById("interest_list_3").classList.remove("hidden");
    });

    interest_list_3_back.addEventListener("click", function handleClick() {
      document.getElementById("interest_list_2").classList.remove("hidden");
      document.getElementById("interest_list_3").classList.add("hidden");
    });
  }
}
