import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "navButton",
    "links",
    "messages",
    "video",
    "overlay",
    "link",
  ];

  initialize() {
    this.players = new Array();
    this.markReadPath = this.data.get("mark-read-path");
    this.isMember = this.data.get("is-member") === "true";
    this.initialClass = this.element.className;
    this.userableId = this.data.get("userable-id");
    this.initializeYouTubeAPI();
    this.markHubRead();

    // Scroll to newest message on load
    this.handleScroll(this.messagesTarget, "auto");

    const observerConfig = {
      attributes: false,
      childList: true,
      subtree: true,
    };

    // Listen for DOM changes to messages stream
    this.messageObserver = new MutationObserver((mutationList, observer) =>
      this.handleMessageMutation(mutationList, observer)
    );
    this.messageObserver.observe(this.messagesTarget, observerConfig);

    // Listen for DOM changes to links stream
    this.linkObserver = new MutationObserver((mutationList, observer) =>
      this.handleLinkMutation(mutationList, observer)
    );
    this.linkObserver.observe(this.linksTarget, observerConfig);
  }

  handleMessageMutation(mutationList, observer) {
    for (const mutation of mutationList) {
      mutation.addedNodes.forEach((node) => {
        if (node.nodeName !== "TURBO-FRAME") return;

        // Remove new messages from turbo stream if they were sent by the current user/organization
        if (
          node.dataset.newMessage === "true" &&
          node.dataset.senderId === this.userableId
        ) {
          node.remove();
          return;
        }

        if (node.dataset.newMessage !== "true") {
          return;
        }

        this.markHubRead(node);
        this.handleScroll(this.messagesTarget, "smooth");
      });
    }
  }

  handleLinkMutation = (mutationList, observer) => {
    for (const mutation of mutationList) {
      mutation.addedNodes.forEach((node) => {
        // Scroll to bottom only if a TURBO-STREAM element is added
        if (node.nodeName !== "TURBO-FRAME") return;

        if (node.dataset.newLink === "true") {
          this.markHubRead(node);
          this.handleScroll(this.linksTarget, "smooth");
        }

        // Check if streamed hub link contains a youtube video
        const videoTarget = node.querySelector("div[data-hub-target='video']");
        if (!videoTarget) return;
        this.embedPlayers();
      });
    }
  };

  markHubRead(node) {
    if (node && node.getAttribute("data-new-message") === "false") return;

    const headers = { "X-CSRF-Token": this.data.get("token") };

    fetch(this.markReadPath, { method: "PATCH", headers })
      .then((res) => {
        if (res.ok) return;
        console.error("Error marking hub as read");
      })
      .catch((err) => console.error(err));
  }

  showLinks(e) {
    this.element.className = `${this.initialClass} show-links`;
    this.handleDisabled(e.target);
  }

  showMessages(e) {
    this.element.className = `${this.initialClass} show-messages`;
    this.handleScroll(this.messagesTarget, "auto");
    this.handleDisabled(e.target);
  }

  handleDisabled(clickedButton) {
    // Disable clicked nav button, enable other nav buttons
    this.navButtonTargets.forEach((button) => {
      if (button !== clickedButton) {
        button.disabled = false;
      } else {
        button.disabled = true;
      }
    });
  }

  jumpToNewestMessage() {
    this.handleScroll(this.messagesTarget);
  }

  handleScroll(element, behavior = "smooth") {
    element.scroll({
      top: element.scrollHeight,
      behavior,
    });
  }

  initializeYouTubeAPI() {
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  embedPlayers() {
    // When API is ready, prepare a player for each youtube thumbnail
    this.videoTargets.forEach((videoContainer) => {
      const iframeTarget = videoContainer.querySelector(
        ".hub-link-card__video__iframe"
      );
      const playBtn = videoContainer.querySelector(
        ".hub-link-card__video__play-btn"
      );
      const elementId = iframeTarget.getAttribute("id");
      const videoId = iframeTarget.getAttribute("data-video-id");

      if (iframeTarget.nodeName === "IFRAME") return;

      this.createPlayer(elementId, videoId, playBtn);
    });
  }

  createPlayer(elementId, videoId, playBtn) {
    new YT.Player(elementId, {
      height: "100%",
      width: "100%",
      videoId,
      playerVars: {
        playsinline: 1,
        modestbranding: 1,
      },
      events: {
        onReady: (event) => this.onPlayerReady(event, playBtn),
        onStateChange: (event) => this.onPlayerStateChange(event, playBtn),
      },
    });
  }

  onPlayerReady(event, playBtn) {
    // Display custom play button only when player is ready
    const player = event.target;
    this.players.push(player);
    playBtn.addEventListener("click", () => {
      player.playVideo();
      this.removeCustomPlayButton(playBtn);
    });
    playBtn.classList.remove("hidden");
  }

  onPlayerStateChange(event) {
    if (event.data !== YT.PlayerState.PLAYING) return;

    // Stop all other players when playing a video, "g" refers to the player's generated iframe element
    const currentPlayer = event.target;
    const otherPlayers = this.players.filter(
      (player) => player.g.id !== currentPlayer.g.id
    );
    otherPlayers.forEach((player) => player.pauseVideo());
  }

  removeCustomPlayButton(playBtn) {
    const parentElement = playBtn.parentElement;

    const thumbnail = parentElement.querySelector(
      ".hub-link-card__video__thumb"
    );
    const iframe = parentElement.querySelector(".hub-link-card__video__iframe");
    playBtn.classList.add("hidden");
    thumbnail.classList.add("hidden");
    iframe.classList.remove("hidden");
  }

  setFocused(event) {
    const focusedLink = this.linkTargets.find((link) => {
      return (
        link.getAttribute("data-hub-link-id") ===
        event.target.getAttribute("data-hub-link-id")
      );
    });
    this.focusedLink = focusedLink;
  }

  setBackdrop() {
    this.overlayTarget.classList.add("active");
    this.focusedLink.classList.add("active");
    this.focusedLink.parentElement.classList.remove("has-notification");
  }

  clearBackdrop() {
    this.overlayTarget.classList.remove("active");
    this.linkTargets.forEach((link) => link.classList.remove("active"));
  }

  disconnect() {
    // Disconnect mutation observers
    this.messageObserver.disconnect();
    this.linkObserver.disconnect();
  }
}
