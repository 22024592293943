import { DIACRITICS_REGEX, EMAIL_REGEX, URL_REGEX } from "../regex";
import { SCREEN_MD, SCREEN_LG, SCREEN_XL } from "../breakpoints";

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("document");
  }
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export function setCookie(name, value) {
  var expires = new Date();
  expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);
  document.cookie =
    name + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
}

export function deleteCookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/";
}

// Use same flash style from app/views/application/_flash.html.erb
export function buildFlash(type, message) {
  const div = document.createElement("div");
  div.className = `flash_message ${type}`;
  div.setAttribute("data-controller", "removals");
  div.setAttribute(
    "data-action",
    "animationend->removals#remove click->removals#remove"
  );
  div.textContent = message;
  return div;
}

export function flashNow(type, message) {
  const flashContainer = findElement("#flash");
  if (!flashContainer) return;

  const flashMessage = buildFlash(type, message);
  flashContainer.innerHTML = "";
  flashContainer.append(flashMessage);
}

// Spinner styles in app/javascript/stylesheets/components/misc.scss
export function buildSpinner() {
  const loader = document.createElement("div");
  loader.className = "loader";
  const spinner = document.createElement("div");
  spinner.className = "loader__spinner active";
  loader.appendChild(spinner);
  return loader;
}

export function removeAccents(string) {
  return string.normalize("NFD").replace(DIACRITICS_REGEX, "");
}

export function isHidden(element) {
  return element.offsetParent === null;
}

export function scrollToBottom(element, behavior = "smooth") {
  element.scroll({
    top: element.scrollHeight,
    behavior,
  });
}

export function dataAttributeToBoolean(context, dataAttribute) {
  const optionValue = context.data.get(dataAttribute);

  if (optionValue) {
    return optionValue === "true";
  } else {
    return null;
  }
}

export function clickedOnElement(event, element) {
  const clientX = event.clientX;
  const clientY = event.clientY;
  const modalRect = element.getBoundingClientRect();
  const insideX = clientX > modalRect.left && clientX < modalRect.right;
  const insideY = clientY > modalRect.top && clientY < modalRect.bottom;
  return insideX && insideY;
}

// Mimic Rails' simple_format
export function simpleFormat(text) {
  // Convert single newlines to line breaks, and double newlines to paragraphs
  const paragraphs = text.split(/\n{2,}/);
  let result = "";
  paragraphs.forEach((paragraph) => {
    const lines = paragraph.split(/\n/);
    let formattedLines = "";
    lines.forEach((line) => {
      if (line.trim() !== "") {
        formattedLines += line.trim() + "<br>";
      }
    });
    if (formattedLines !== "") {
      result += `<p>${formattedLines}</p>`;
    }
  });

  return result;
}

// Mimic Rails' auto_link
export function autoLink(text) {
  // Convert email addresses to mailto links
  const textWithEmails = text.replace(
    EMAIL_REGEX,
    '<a href="mailto:$1">$1</a>'
  );

  // Convert regular links to links with target="_blank"
  const textWithLinks = textWithEmails.replace(
    URL_REGEX,
    '<a href="$1" target="_blank">$1</a>'
  );

  return textWithLinks;
}

// Mimic Rails' simple_format and auto_link
export function simpleFormatAutoLink(text) {
  const formattedText = simpleFormat(text);
  const textWithLinks = autoLink(formattedText);
  return textWithLinks;
}

export function getCsrfToken() {
  const csrfToken = document.querySelector("meta[name=csrf-token]");
  return csrfToken ? csrfToken.content : null;
}

export function isScreenPortrait() {
  return window.innerHeight > window.innerWidth;
}

export function isScreenMd() {
  return window.innerWidth >= SCREEN_MD;
}

export function isScreenLg() {
  return window.innerWidth >= SCREEN_LG;
}

export function isScreenXl() {
  return window.innerWidth >= SCREEN_XL;
}
