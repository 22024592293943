import { Controller } from "@hotwired/stimulus";
import tippy, { hideAll } from "tippy.js";

export default class extends Controller {
  static values = {
    content: String, // The tooltip's content
    hideAllOnShow: Boolean, // Whether to hide all other tooltips when this one is shown
  };

  static targets = ["trigger", "content"];

  initialize() {
    const content = this.setContent();
    const theme = this.data.get("theme");
    const trigger = this.data.get("event") || "click";
    const timeout = this.data.get("timeout");
    const placement = this.data.get("placement") || "top";
    const showOnLoad = this.data.get("show-on-load") === "true";
    const hideAllOnShow = this.hideAllOnShowValue;

    this.tooltip = tippy(this.triggerTarget, {
      interactive: true,
      allowHTML: true,
      content,
      theme,
      trigger,
      placement,
      onShow(instance) {
        if (hideAllOnShow) {
          hideAll({ exclude: instance });
        }

        if (!timeout) return;
        setTimeout(() => instance.hide(1000), parseInt(timeout));
      },
    });

    if (showOnLoad) {
      this.show();
    }

    this.element.addEventListener("tooltip:show", this.show);
  }

  disconnect() {
    this.element.removeEventListener("tooltip:show", this.show);
    this.tooltip.destroy();
  }

  show = () => {
    this.tooltip.show();
  };

  setContent() {
    if (this.hasContentValue) {
      return this.contentValue;
    } else if (this.hasContentTarget) {
      return this.contentTarget.innerHTML;
    } else {
      console.error("TooltipController: No content found for tooltip");
    }
  }
}
