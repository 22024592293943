import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkBox", "submit", "error", "form"];

  validate() {
    if (this.checkBoxTargets.every((box) => !box.checked)) {
      this.disableSubmit();
    } else {
      this.enableSubmit();
    }
  }

  handleSubmit() {
    if (this.checkBoxTargets.every((box) => !box.checked)) {
      this.errorTarget.textContent =
        this.errorTarget.getAttribute("data-error-msg");
    } else {
      this.formTarget.requestSubmit();
    }
  }

  disableSubmit() {
    this.submitTarget.classList.add("disabled");
  }

  enableSubmit() {
    this.submitTarget.classList.remove("disabled");
  }
}
