import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["radio"];

  onClick(e) {
    if (!this.radioTarget.checked) return;

    this.radioTarget.checked = false;
    e.preventDefault();
  }
}
