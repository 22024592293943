import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "button", // Toggles the visibility of the field
    "container", // Container that holds the field
    "input", // The input field
  ];

  static values = {
    removeText: String, // Text to display on the button when the field is visible
  };

  connect() {
    this.initialButtonText = this.buttonTarget.textContent;
    this.visible = false;
    this.addAriaAttributes();
    this.initializeVisibility();
  }

  addAriaAttributes() {
    const controlledId = this.containerTarget.id || this.inputTarget.id;
    this.buttonTarget.setAttribute("aria-expanded", "false");
    this.buttonTarget.setAttribute("aria-controls", controlledId);
    this.containerTarget.setAttribute("aria-hidden", "true");
  }

  initializeVisibility() {
    if (this.inputTarget.value) {
      this.showField();
    } else {
      this.hideField();
    }
  }

  showField() {
    this.buttonTarget.setAttribute("aria-expanded", "true");
    this.containerTarget.setAttribute("aria-hidden", "false");
    this.containerTarget.style.display = "";
    this.buttonTarget.textContent = this.removeTextValue;
    this.inputTarget.focus();
    this.visible = true;
    this.element.classList.add("field-visible");
  }

  hideField() {
    this.buttonTarget.setAttribute("aria-expanded", "false");
    this.containerTarget.setAttribute("aria-hidden", "true");
    this.containerTarget.style.display = "none";
    this.buttonTarget.textContent = this.initialButtonText;
    this.inputTarget.value = "";
    this.visible = false;
    this.element.classList.remove("field-visible");
  }

  toggle() {
    if (this.visible) {
      this.hideField();
    } else {
      this.showField();
    }
  }
}
