import { Controller } from "@hotwired/stimulus";
import { findElement } from "../helpers";
import consumer from "../channels/consumer";

export default class extends Controller {
  static targets = ["hubCount", "chatMessageCount"];

  connect() {
    this.currentNotifiableId = this.data.get("notifiable-id");
    this.subscription = consumer.subscriptions.create(
      { channel: "NotificationChannel" },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    const { notification, counts } = data;

    // Ignore unread notifications for the notifiable that is currently being viewed, if any
    if (
      notification.notifiable_id === this.currentNotifiableId &&
      !notification.read_at
    )
      return;

    switch (notification.notifiable_type) {
      case "Hub":
        this.updateHubUnreadCounts(counts);
        this.updateHubCard(notification);
        break;
      case "Chat":
        this.updateChatMessageCount(counts.total);
        this.updateChatCardCount(
          notification.notifiable_id,
          notification.read_at
        );
        break;
    }
  }

  // This is called when a custom event named updateNotifiableId is fired
  updateId(event) {
    const newId = event.detail;
    this.currentNotifiableId = newId;
    this.element.setAttribute("data-notifiable-id", newId);
  }

  // This is called when a custom event named clearNotifiableId is fired
  clearId() {
    this.currentNotifiableId = null;
    this.element.setAttribute("data-notifiable-id", null);
  }

  updateNotificationClass(element, count) {
    if (count > 0) {
      element.classList.add("has-notifications");
    } else {
      element.classList.remove("has-notifications");
    }
  }

  setCount(element, count) {
    element.setAttribute("data-notification-count", count > 99 ? "99+" : count);
  }

  updateHubUnreadCounts(counts) {
    this.hubCountTargets.forEach((hubCount) => {
      const scope = hubCount.getAttribute("data-notification-scope");
      const count = counts[scope] || 0;
      this.setCount(hubCount, count);
      this.updateNotificationClass(hubCount, count);
    });
  }

  updateHubCard(notification) {
    const hubCard = findElement(
      `a[data-hub-id="${notification.notifiable_id}"]`
    );

    if (!hubCard) return;

    if (notification.read_at) {
      hubCard.classList.remove("has-notifications");
    } else {
      hubCard.classList.add("has-notifications");
    }
  }

  updateChatMessageCount(count) {
    this.chatMessageCountTargets.forEach((chatMessageCount) => {
      this.setCount(chatMessageCount, count);
      this.updateNotificationClass(chatMessageCount, count);
    });
  }

  updateChatCardCount(chatId, readAt) {
    const chatCard = findElement(`a[data-chat-id="${chatId}"]`);
    if (!chatCard) return;

    if (readAt) {
      return chatCard.classList.remove("has-notifications");
    }
  }
}
