import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["paymentMethod", "confirmation"];

  initialize() {
    this.onStripeSuccess = this.onStripeSuccess.bind(this);
    document.addEventListener("stripe:success", this.onStripeSuccess);
  }

  onStripeSuccess() {
    this.paymentMethodTarget.setAttribute("aria-hidden", true);
    this.confirmationTarget.setAttribute("aria-hidden", false);
  }

  disconnect() {
    document.removeEventListener("stripe:success", this.onStripeSuccess);
  }
}
