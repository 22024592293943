import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["searchResults", "searchInput"];

  initialize() {
    document.addEventListener("click", this.handleClickOutsideBox);
  }

  clearInput() {
    this.searchInputTarget.value = "";
  }

  handleClickOutsideBox(event) {
    if (document.querySelector(".global-modal.search-modal")) return;

    const box = document.querySelector("[data-header-target='searchResults']");

    if (box && !box.contains(event.target) && box.innerHTML) {
      box.innerHTML = "";
      box.removeAttribute("class");
    }
  }
}
