import { Controller } from "@hotwired/stimulus";
import { findElement, removeAccents } from "../helpers";

export default class extends Controller {
  static targets = ["resultsContainer", "filterable"];

  handleInput(e) {
    const searchQuery = this.normalize(e.target.value);

    this.filter(searchQuery);
  }

  filter(searchQuery) {
    this.filterableTargets.forEach((filterable) => {
      const originalValue = filterable.getAttribute("data-filter-value");
      const normalizedValue = this.normalize(originalValue);

      if (normalizedValue.includes(searchQuery)) {
        this.revealMatch(filterable);
      } else {
        this.hideNonMatch(filterable);
      }
    });
  }

  revealMatch(filterable) {
    filterable.classList.remove("hidden");
    if (this.data.get("input") === "disable")
      findElement(filterable, "input").disabled = false;
  }

  hideNonMatch(filterable) {
    filterable.classList.add("hidden");
    if (this.data.get("input") == "disable")
      // Disable hidden input for filterable object if filtered object is not displayed
      findElement(filterable, "input").disabled = true;
  }

  normalize(string) {
    return removeAccents(string.trim().toLowerCase());
  }
}
