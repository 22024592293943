import { Controller } from "@hotwired/stimulus";
import { findElement } from "../helpers";

export default class extends Controller {
  connect() {
    this.paywallActive = this.data.get("active") === "true";
    this.paywallFrame = findElement("#paywall-modal");
    this.paywallPath = "/subscription/new";
    this.returnUrl = this.data.get("returnUrl");
  }

  onClick(event) {
    if (!this.paywallActive) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();
    const subscriptionPath = `${this.paywallPath}?return_url=${this.returnUrl}`;
    this.paywallFrame.src = subscriptionPath;
  }
}
