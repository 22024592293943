import { Controller } from "@hotwired/stimulus";
import { buildSpinner } from "../helpers";

export default class extends Controller {
  static targets = ["submit"];

  static values = {
    keepInitialWidth: Boolean,
  };

  initialize() {
    this.initialBtnContent = this.submitTarget.innerHTML;
    this.loadingText = this.submitTarget.dataset.loadingText || "";
    this.initialBtnWidth = this.submitTarget.offsetWidth;

    if (this.element.dataset.turbo === "false") {
      this.addNonTurboEventListeners();
    } else {
      this.addTurboEventListeners();
    }
  }

  addNonTurboEventListeners() {
    this.element.addEventListener("submit", this.handleSubmitStart.bind(this));
  }

  addTurboEventListeners() {
    this.element.addEventListener(
      "turbo:submit-start",
      this.handleSubmitStart.bind(this)
    );
    this.element.addEventListener(
      "turbo:submit-end",
      this.handleSubmitEnd.bind(this)
    );
  }

  handleSubmitStart() {
    const spinner = buildSpinner();
    this.submitTarget.disabled = true;
    this.submitTarget.classList.add("disabled");
    this.submitTarget.innerHTML = this.loadingText;
    this.submitTarget.prepend(spinner);

    if (this.keepInitialWidthValue) {
      this.submitTarget.style.width = `${this.initialBtnWidth}px`;
    }
  }

  handleSubmitEnd() {
    this.submitTarget.disabled = false;
    this.submitTarget.innerHTML = this.initialBtnContent;

    if (this.keepInitialWidthValue) {
      this.submitTarget.style.width = "";
    }
  }
}
