import { Controller } from "@hotwired/stimulus";
import { flashNow } from "../helpers";

export default class extends Controller {
  static targets = ["new_link", "preview"];

  initialize() {
    this.addedLinks = [];
    this.deleteIconPath = this.data.get("delete-img");
    this.placeholderPath = this.data.get("placeholder");
    this.inspectorPath = this.data.get("inspector-path");
    this.new_linkTarget.addEventListener("paste", (event) => {
      this.handlePaste(event);
    });
  }

  display(e) {
    if (e.code === "Enter") {
      e.preventDefault();
      this.processLink(e.target.value);
      this.new_linkTarget.value = "";
    }
  }

  handlePaste(event) {
    let paste = (event.clipboardData || window.clipboardData).getData("text");
    this.processLink(paste);
    setTimeout(() => {
      this.new_linkTarget.value = "";
    }, 500);
  }

  async processLink(url) {
    const link = url.trim();

    if (link.length === 0) return;
    if (this.addedLinks.includes(link))
      return flashNow("alert", "Duplicate link");

    this.addedLinks.push(link);
    const previewCard = this.buildCard(this.loadingHtml());
    previewCard.innerHTML = this.loadingHtml();
    previewCard.classList.add("hub-link-card--loading");
    this.previewTarget.appendChild(previewCard);
    this.handleScroll();
    const response = await fetch(`${this.inspectorPath}?link=${link}`);
    const json = await response.json();

    if (response.ok && this.hasDuplicates(json.link)) {
      previewCard.remove();
      flashNow("alert", "Duplicate link");
    } else if (response.ok) {
      previewCard.innerHTML = "";
      this.appendToPreview(previewCard, json);
    } else {
      previewCard.innerHTML = `<span class="error">${json.error}</span>`;
    }

    previewCard.prepend(this.buildDelete(link));
  }

  // Links inputted by the user may not be in the same format returned by the backend, check for duplicates again
  hasDuplicates(link) {
    return this.element.querySelector(`div[data-url="${link}"]`);
  }

  appendToPreview(card, json) {
    const { hiddenInputs, image, href } = this.buildPreview(json);
    card.appendChild(hiddenInputs);
    card.appendChild(image);
    card.appendChild(href);
    card.setAttribute("data-url", json.link);
  }

  handleScroll() {
    if (window.innerHeight >= 768 && window.innerWidth >= 640) {
      this.previewTarget.scroll({
        top: this.previewTarget.scrollHeight,
        behavior: "smooth",
      });
    } else {
      this.previewTarget.scroll({
        left: this.previewTarget.scrollWidth,
        behavior: "smooth",
      });
    }
  }

  buildDelete(originalUrl) {
    const btn = document.createElement("button");
    btn.type = "button";
    btn.className = "hub-link-card__delete";
    const btnImage = document.createElement("img");
    btnImage.src = this.deleteIconPath;
    btn.appendChild(btnImage);
    btn.addEventListener("click", (e) => {
      e.stopPropagation();
      this.addedLinks = this.addedLinks.filter((item) => item !== originalUrl);
      btn.parentElement.remove();
    });

    return btn;
  }

  buildCard(html) {
    const card = document.createElement("div");
    card.className = "hub-link-card";
    card.setAttribute("data-controller", "hub-target");
    card.setAttribute("data-hub-card-target", "remove");
    card.innerHTML = html;
    return card;
  }

  buildPreview(obj) {
    const [linkInput, imageInput, titleInput] = [
      "link",
      "image_url",
      "title",
    ].map((attribute) => {
      const element = document.createElement("input");
      element.type = "hidden";
      element.name = `hub[hub_links_attributes][][${attribute}]`;
      return element;
    });
    linkInput.value = obj.link;
    imageInput.value = obj.image_url;
    titleInput.value = obj.title;
    const hiddenInputs = document.createElement("div");
    hiddenInputs.classList.add("hidden");
    [linkInput, imageInput, titleInput].forEach((input) => {
      hiddenInputs.appendChild(input);
    });

    const image = document.createElement("img");
    image.setAttribute("src", obj.image_url || this.placeholderPath);
    image.className = "hub-link-card__image";

    const href = document.createElement("a");
    href.setAttribute("href", obj.link);
    href.setAttribute("target", "_blank");
    href.className = "hub-link-card__text";

    const strong = document.createElement("strong");
    strong.textContent = obj.title;

    const span = document.createElement("span");
    span.textContent = obj.link;

    href.appendChild(strong);
    href.appendChild(span);

    return {
      hiddenInputs,
      image,
      href,
    };
  }

  loadingHtml() {
    return `
      <div class="loader">
        <div class="loader__spinner loader__spinner active"></div>
        <span>Loading...</span>
      </div>`;
  }
}
