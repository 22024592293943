import { Controller } from "@hotwired/stimulus";
import { findElement } from "../helpers";
import TomSelect from "tom-select";

export default class extends Controller {
  connect() {
    const maxOptions = this.data.get("max-options")
      ? parseInt(this.data.get("max-options"))
      : null;
    const placeholder = this.data.get("placeholder");
    const valueField = this.data.get("value") || "value";
    const labelField = this.data.get("label") || "text";
    const searchField = this.data.get("search") || ["text"];
    const maxItems = this.data.get("multiple") ? null : 1;
    const minQueryLength = this.data.get("min-length") || 1;
    const create = this.data.get("allow-create") || false;
    this.allowOther = this.data.get("other") || false;
    this.customFocus = this.data.get("focus") || false;

    this.tomSelect = new TomSelect(this.element, {
      create,
      loadThrottle: 300,
      maxOptions,
      maxItems,
      placeholder,
      valueField,
      labelField,
      searchField,
      shouldLoad: (query) => {
        if (query.length >= minQueryLength) return true;
        return false;
      },
      load: (query, callback) => {
        this.fetchRemoteData(query, callback);
      },
      render: {
        no_results: (data, escape) => {
          return this.renderNoReuslts(data, escape);
        },
        loading: () => {
          return "<div></div>";
        },
      },
    });

    this.appendLoader();

    if (this.data.get("interests")) this.initializeInterests();
    if (this.data.get("nosearch")) this.tomSelect.control_input.disabled = true;
  }

  updateInterestContainer(container) {
    const isEmpty = this.tomSelect.items.length === 0;
    container.classList.toggle("is-empty", isEmpty);
  }

  initializeInterests() {
    const interestContainer = findElement("#interest-container");

    const handleRemove = (copy, item) => {
      copy.classList.add("hidden");
      this.tomSelect.removeItem(item);
      this.updateInterestContainer(interestContainer);
    };

    // Copy selected items to seperate container, hide default selected items
    const copyItem = (item) => {
      const div = document.createElement("div");
      div.textContent = item.textContent;
      const button = document.createElement("button");
      button.type = "button";
      button.addEventListener("click", () => handleRemove(div, item));
      div.appendChild(button);
      interestContainer.appendChild(div);
      this.updateInterestContainer(interestContainer);
      if (this.tomSelect.hasOptions) return;
      this.tomSelect.control_input.value = "";
      this.tomSelect.refreshOptions();
    };

    // Copy items onload if preselected values exist (reopening filter)
    const copyPreselectedItems = () => {
      if (this.tomSelect.items.length === 0) return;
      this.tomSelect.control
        .querySelectorAll("div")
        .forEach((item) => copyItem(item));
    };

    copyPreselectedItems();
    this.tomSelect.on("item_add", (value, item) => copyItem(item));
  }

  // Override default loading
  appendLoader() {
    const dropdownContainer = this.tomSelect.dropdown;
    const spinner = document.createElement("div");
    spinner.className = "spinner";
    dropdownContainer.prepend(spinner);
  }

  // Override default no results
  renderNoReuslts() {
    if (this.allowOther) {
      return `<div data-selectable="" data-value="Other" class="option active" role="option" id="${this.element.id}-opt-1" aria-selected="true">Other</div>`;
    } else {
      return '<div class="no-results">No results found</div>';
    }
  }

  fetchRemoteData(query, callback) {
    const baseUrl = this.data.get("url");
    const noParams = this.data.get("no-params");

    if (this.loading > 1 || !baseUrl) {
      callback();
      return;
    }

    const fetchUrl = !!noParams ? baseUrl : baseUrl + encodeURIComponent(query);

    fetch(fetchUrl, this.setFetchOptions())
      .then((response) => response.json())
      .then((json) => {
        callback(json);
        this.settings.load = null;
      })
      .catch(() => {
        callback();
      });
  }

  setFetchOptions() {
    const authToken = this.data.get("auth-token");
    const authEmail = this.data.get("auth-email");

    const baseOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: null,
    };

    let fetchOptions = { ...baseOptions };

    if (authToken && authEmail) {
      const auth = `Token token=${authToken}, email=${authEmail}`;
      fetchOptions = {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          Authorization: auth,
        },
      };
    }

    return fetchOptions;
  }
}
