import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "guestWall", // The element that should be displayed when a guest attempts to click anything
    "guestWallContent", // The element that contains the content of the guest wall
  ];

  connect() {
    console.log("GuestWallController#connect");
    window.addEventListener("click", this.handleClick);
    window.addEventListener("keyup", this.handleKeyup);
  }

  disconnect() {
    window.removeEventListener("click", this.handleClick);
    window.removeEventListener("keyup", this.handleKeyup);
  }

  displayGuestWall() {
    this.guestWallTarget.setAttribute("aria-hidden", false);
  }

  hideGuestWall() {
    this.guestWallTarget.setAttribute("aria-hidden", true);
  }

  guestWallIsVisible() {
    return this.guestWallTarget.getAttribute("aria-hidden") === "false";
  }

  handleClick = (event) => {
    event.stopPropagation();

    if (this.guestWallContentTarget.contains(event.target)) {
      return;
    } else if (this.guestWallIsVisible()) {
      this.hideGuestWall();
      return;
    }

    event.preventDefault();
    this.displayGuestWall();
  };

  handleKeyup = (event) => {
    if (event.code !== "Escape" || !this.guestWallIsVisible()) {
      return;
    }

    this.hideGuestWall();
  };
}
