import { Controller } from "@hotwired/stimulus";
import { loadStripe } from "@stripe/stripe-js";
import { buildSpinner } from "../helpers";

export default class extends Controller {
  static targets = ["payment", "error", "submit"];

  async initialize() {
    this.stripe = await loadStripe(this.data.get("public-key"));
    this.returnUrl = this.data.get("return-url");
    this.csrfToken = this.data.get("csrf-token");
    this.noRedirect = this.data.get("no-redirect") === "true";
    this.buttonText = this.submitTarget.textContent;

    if (!this.stripe) {
      this.handleError("Failed to load Stripe.js");
      return;
    }

    this.buildElements();
  }

  setSpinner() {
    const spinner = buildSpinner();
    this.submitTarget.disabled = true;
    this.submitTarget.textContent = "";
    this.submitTarget.appendChild(spinner);
  }

  clearSpinner() {
    this.submitTarget.disabled = false;
    this.submitTarget.textContent = this.buttonText;
  }

  handleError(errorMessage) {
    this.errorTarget.textContent = errorMessage;
    this.clearSpinner();
  }

  // For Elements Appearance Options, see:
  // https://stripe.com/docs/elements/appearance-api
  options() {
    const clientSecret = this.data.get("client-secret");

    return {
      clientSecret,
      appearance: {
        variables: {
          fontSizeBase: "13px",
        },
        rules: {
          ".Label": {
            fontWeight: "bold",
            color: "#4f5b76",
          },
          ".Input": {
            paddingTop: "13px",
            paddingBottom: "13px",
          },
        },
      },
    };
  }

  buildElements() {
    this.elements = this.stripe.elements(this.options());
    const paymentElement = this.elements.create("payment");
    paymentElement.mount(this.paymentTarget);
  }

  async confirmSetupIntent() {
    return await this.stripe.confirmSetup({
      elements: this.elements,
      confirmParams: {
        return_url: this.returnUrl,
      },
      redirect: "if_required",
    });
  }

  async sendPaymentMethod(paymentMethodId) {
    const path = this.data.get("send-path");
    const method = this.data.get("send-method");

    return await fetch(path, {
      method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": this.csrfToken,
      },
      body: JSON.stringify({
        payment_method_id: paymentMethodId,
      }),
    });
  }

  fireSuccessEvent() {
    const event = new CustomEvent("stripe:success", {
      bubbles: true,
      cancelable: true,
    });

    document.dispatchEvent(event);
  }

  async submit(event) {
    event.preventDefault();
    this.setSpinner();

    // Validate the payment form
    const { error: paymentFormError } = await this.elements.submit();

    if (paymentFormError) {
      this.clearSpinner();
      return;
    }

    // Confirm the SetupIntent
    const {
      setupIntent: { payment_method: paymentMethodId } = {},
      error: setupIntentError,
    } = await this.confirmSetupIntent().catch((error) => {
      this.handleError(error.message);
      return;
    });

    if (setupIntentError) {
      this.handleError(setupIntentError.message);
      return;
    }

    // Send the payment method ID to the specified path
    const sendPaymentMethodRes = await this.sendPaymentMethod(paymentMethodId);

    const { error: sendPaymentMethodError } = await sendPaymentMethodRes.json();

    if (sendPaymentMethodError) {
      this.handleError(sendPaymentMethodError);
      return;
    }

    this.fireSuccessEvent();

    if (this.noRedirect) {
      this.clearSpinner();
      return;
    }

    window.location.replace(this.returnUrl);
  }
}
