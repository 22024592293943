import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";
import { SCREEN_MD } from "../breakpoints";

export default class extends Controller {
  static targets = ["mobileInput"];

  connect() {
    this.handleResize();

    window.addEventListener(
      "resize",
      throttle(() => this.handleResize(), 500)
    );
  }

  handleResize() {
    if (!this.hasMobileInputTarget) return;

    if (window.innerWidth >= SCREEN_MD) {
      this.disableMobileInputs();
    } else {
      this.enableMobileInputs();
    }
  }

  disableMobileInputs() {
    this.mobileInputTargets.forEach((input) => {
      input.disabled = true;
    });
  }

  enableMobileInputs() {
    this.mobileInputTargets.forEach((input) => {
      input.disabled = false;
    });
  }
}
