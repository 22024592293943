import { Controller } from "@hotwired/stimulus";
import { getCsrfToken } from "../helpers";
import tippy from "tippy.js";

export default class extends Controller {
  static targets = ["copyButton"];

  static values = {
    url: String, // The URL to share
    text: String, // The text to share for Twitter
    saveShare: { type: Boolean, default: true }, // Whether to save share clicks
    shareableType: String, // Required if saveShare is true
    shareableId: String, // Required if saveShare is true
    shareCountElementId: String, // The ID of the element to increment the share count
  };

  initialize() {
    if (this.hasCopyButtonTarget) {
      this.initializeTippy();
    }
  }

  initializeTippy() {
    this.tooltip = tippy(this.copyButtonTarget, {
      content: "Copied to clipboard!",
      onShow(instance) {
        setTimeout(() => instance.hide(1500), 1000);
      },
      placement: "bottom",
      trigger: "manual",
    });
  }

  shareFacebook() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + this.urlValue,
      "facebook-share-dialog",
      "width=800,height=600"
    );
    this.saveShareClick("facebook");
    return false;
  }

  shareTwitter() {
    this.openWindow(
      `https://twitter.com/intent/tweet?url=${this.urlValue}&text=${this.textValue}`
    );
    this.saveShareClick("twitter");
  }

  shareLinkedin() {
    this.openWindow(
      `https://www.linkedin.com/shareArticle?mini=true&url=${this.urlValue}`
    );
    this.saveShareClick("linkedin");
  }

  openWindow(url) {
    window.open(url, "popup", "width=600,height=600");
  }

  copyLink() {
    navigator.clipboard.writeText(this.urlValue);
    this.tooltip.show();
    this.saveShareClick("copy_link");
  }

  saveShareClick(platform) {
    if (!this.saveShareValue) {
      return;
    }

    const postPath = "/share_clicks";
    const csrfToken = getCsrfToken();
    const data = {
      share_click: {
        shareable_type: this.shareableTypeValue,
        shareable_id: this.shareableIdValue,
        platform,
      },
    };

    this.postShareData(postPath, csrfToken, data);
  }

  postShareData(postPath, csrfToken, data) {
    fetch(postPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          console.error("Error logging share click");
          return;
        }
        this.element.classList.add(`shared-${data.share_click.platform}`); // For test expectations
        this.incrementShareCount();
      })
      .catch((error) => {
        console.error("Error logging share click", error);
      });
  }

  findShareCountElement() {
    const elementId = this.shareCountElementIdValue || "share-count";
    return document.getElementById(elementId);
  }

  incrementShareCount() {
    const shareCountElement = this.findShareCountElement();

    if (!shareCountElement) {
      return;
    }

    const currentCount = parseInt(shareCountElement.innerText, 10);

    if (isNaN(currentCount)) {
      return;
    }

    shareCountElement.innerText = currentCount + 1;
  }
}
