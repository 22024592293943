import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["textarea"];

  initialize() {
    this.autoResize();
  }

  autoResize() {
    this.textareaTarget.style.height = "auto";
    this.textareaTarget.style.height = this.textareaTarget.scrollHeight + "px";
  }
}
