import { Controller } from "@hotwired/stimulus";
let throttle = require("lodash/throttle");

export default class extends Controller {
  initialize() {
    window.addEventListener(
      "scroll",
      throttle(() => this.handleScroll(), 1000)
    );
  }

  handleScroll() {
    const currentPos =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (currentPos > window.innerHeight) return this.displayBtn();
    this.hideBtn();
  }

  displayBtn() {
    this.element.classList.add("active");
  }

  hideBtn() {
    this.element.classList.remove("active");
  }

  handleClick() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.hideBtn();
  }
}
