import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";
import { findElement } from "../helpers";

export default class extends Controller {
  static targets = ["comments", "form", "textarea", "subject", "error"];

  initialize() {
    this.setBackdrop();
    this.handleScroll(this.commentsTarget, "auto");
    this.commentsTarget.addEventListener(
      "scroll",
      throttle(() => this.handleBounce(), 50)
    );
  }

  handleBounce() {
    if (this.commentsTarget.scrollTop < 5) {
      this.commentsTarget.scroll({
        top: 30,
        behavior: "smooth",
      });
    }
  }

  setBackdrop() {
    const hubLinkCommentsModal = findElement("#hub-link-comments-modal");
    if (hubLinkCommentsModal) return;

    // Wait until modal has loaded then set the hub link backdrop styling
    const event = new CustomEvent("setBackdrop");
    window.dispatchEvent(event);
  }

  showForm() {
    this.element.classList.add("active");
    this.handleScroll(this.commentsTarget);
    this.textareaTarget.focus();
  }

  hideForm() {
    this.element.classList.remove("active");
    this.subjectTarget.value = "";
    this.textareaTarget.value = "";
    this.textareaTarget.style.height = "auto";
    if (!this.hasErrorTarget) return;
    this.errorTarget.remove();
  }

  handleScroll(element, behavior = "smooth") {
    element.scroll({
      top: element.scrollHeight,
      behavior,
    });
  }

  submitEnd(e) {
    if (!e.detail.success) return;

    this.hideForm();
    this.updateCommentIcon();
    setTimeout(() => this.handleScroll(this.commentsTarget), 50);
  }

  updateCommentIcon() {
    if (this.data.get("empty") !== "true") return;

    const commentBtn = findElement(`#comment-btn_${this.data.get("link-id")}`);
    commentBtn.classList.remove("empty");
  }
}
