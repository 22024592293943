import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  initialize() {
    this.path = this.data.get("path");
    this.element.addEventListener("click", this.handleClick);
    this.overrideLink();
  }

  replaceableAnchorTag() {
    return this.element.tagName === "A" && this.path;
  }

  overrideLink() {
    if (!this.replaceableAnchorTag()) return;

    this.element.href = this.path;
  }

  handleClick = (e) => {
    if (this.replaceableAnchorTag()) return;

    e.preventDefault();
    Turbo.visit(this.path);
  };

  disconnect() {
    this.element.removeEventListener("click", this.handleClick);
  }
}
