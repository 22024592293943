import { Controller } from "@hotwired/stimulus";
import { setCookie } from "../helpers";

export default class extends Controller {
  static targets = [
    "latitude",
    "longitude",
    "consentCheckbox",
    "spinner",
    "loadMessage",
    "button",
    "resultContainer",
    "result",
  ];

  getCoords = () => {
    if (!this.consentProvided()) return;

    this.setLoading();

    const options = {
      timeout: 15000,
      maximumAge: 600000,
    };

    const error = (err) => {
      this.clearLoading(err.message);
    };

    const success = (pos) => {
      const lat = pos.coords.latitude;
      const long = pos.coords.longitude;
      this.updateInputs(lat, long);
      this.updateCookies(lat, long);
      this.geocode([lat, long]);
    };

    navigator.geolocation.getCurrentPosition(success, error, options);
  };

  updateInputs(lat, long) {
    if (!this.hasLatitudeTarget || !this.hasLongitudeTarget) return;
    this.latitudeTarget.value = lat;
    this.longitudeTarget.value = long;
  }

  updateCookies(lat, long) {
    if (this.hasLatitudeTarget || this.hasLongitudeTarget) return;
    setCookie("latitude", lat);
    setCookie("longitude", long);
  }

  geocode(location) {
    if (!this.hasResultContainerTarget) return this.clearLoading();
    let fetchUrl = "/api/v1/autocomplete/geocode?";

    if (Array.isArray(location)) {
      fetchUrl += `lat=${location[0]}&long=${location[1]}`;
    } else {
      fetchUrl += `location=${location}`;
    }

    const auth = `Token token=${this.data.get(
      "auth-token"
    )}, email=${this.data.get("auth-email")}`;

    fetch(fetchUrl, {
      headers: {
        Authorization: auth,
      },
    })
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            this.renderResult(json);
            this.clearLoading();
          });
        } else {
          this.clearLoading("Could not retrieve geolocation data.");
        }
      })
      .catch((err) => this.clearLoading(err.message));
  }

  renderResult(json) {
    const data = json.data;
    this.resultTarget.textContent = `
    ${data.address.city || data.address.city_district}, 
    ${data.address.state}`;
    this.resultContainerTarget.classList.remove("hidden");
  }

  consentProvided() {
    if (!this.hasConsentCheckboxTarget) return true;
    if (this.consentCheckboxTarget.checked) return true;

    this.latitudeTarget.value = "";
    this.longitudeTarget.value = "";
    return false;
  }

  setLoading() {
    if (!this.hasSpinnerTarget) return;
    this.buttonTarget.disabled = true;
    this.spinnerTarget.classList.add("active");

    const loadingMsg = this.loadMessageTarget.getAttribute("data-loading");

    if (loadingMsg && this.hasLoadMessageTarget) {
      this.loadMessageTarget.textContent = loadingMsg;
    }
  }

  clearLoading(error) {
    if (!this.hasSpinnerTarget) return;
    this.buttonTarget.disabled = false;
    this.spinnerTarget.classList.remove("active");
    this.loadMessageTarget.className = error ? "error" : "success";

    let messageContent;
    if (error) {
      this.dispatchShowEdit();
      messageContent = error;
    } else {
      messageContent =
        this.loadMessageTarget.getAttribute("data-success") || "Success";
    }
    this.loadMessageTarget.textContent = messageContent;
  }

  dispatchShowEdit() {
    this.resultContainerTarget.classList.remove("hidden");
    const event = new CustomEvent("show-location-edit");
    window.dispatchEvent(event);
  }
}
