import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["marker", "action", "input"];

  connect() {
    this.initialActionText = this.actionTarget.textContent;
    this.afterActionText = this.actionTarget.getAttribute("data-after-text");
    this.marked = false;
  }

  toggleMarker() {
    this.marked = !this.marked;
    const event = new CustomEvent("verify-marked");
    window.dispatchEvent(event);
    if (this.marked) return this.markForDelete();
    this.unmarkForDelete();
  }

  markForDelete() {
    this.inputTarget.disabled = false;
    this.actionTarget.textContent = this.afterActionText;
    this.markerTarget.classList.add("active");
  }

  unmarkForDelete() {
    this.inputTarget.disabled = true;
    this.actionTarget.textContent = this.initialActionText;
    this.markerTarget.classList.remove("active");
  }
}
