import { Controller } from "@hotwired/stimulus";
import { flashNow, getCsrfToken } from "../helpers";

export default class extends Controller {
  static values = {
    addPath: String,
    day: String,
    destroyAllPath: String,
    maxSlots: Number,
  };

  static targets = ["addButton", "noSlotsMessage", "toggle"];

  requestHeaders = {
    Accept: "text/vnd.turbo-stream.html",
    "Content-Type": "application/json",
    "X-CSRF-Token": getCsrfToken(),
  };

  turboFetch(path, method, action) {
    fetch(path, {
      method,
      dataType: "script",
      credentials: "include",
      headers: this.requestHeaders,
      body: JSON.stringify({ day: this.dayValue }),
    })
      .then((response) => {
        if (!response.ok) {
          this.handleError(action, response);
          return;
        }

        response
          .text()
          .then((html) => Turbo.renderStreamMessage(html))
          .catch((error) => this.handleError(action, error));
      })
      .catch((error) => this.handleError(action, error));
  }

  handleError(action, error) {
    console.error(error);
    flashNow("alert", `Failed to ${action} time slot`);
  }

  turboAddSlot() {
    this.turboFetch(this.addPathValue, "POST", "add");
  }

  turboDestroyAll() {
    this.turboFetch(this.destroyAllPathValue, "DELETE", "delete");
  }

  toggle(event) {
    const { checked } = event.target;

    if (checked) {
      this.turboAddSlot();
      return;
    }

    this.turboDestroyAll();
  }

  timeSlotsChanged(event) {
    const { day } = event.detail;

    if (day !== this.dayValue) {
      return;
    }

    this.updateAddButtonState();
    this.updateNoSlotsAndToggleState();
  }

  getSlotCount() {
    return this.element.querySelectorAll(
      `[data-controller="manage-time-slot-entry"]`
    ).length;
  }

  updateAddButtonState() {
    const slotCount = this.getSlotCount();
    this.addButtonTarget.disabled = slotCount >= this.maxSlotsValue;
  }

  updateNoSlotsAndToggleState() {
    const slotCount = this.getSlotCount();
    this.noSlotsMessageTarget.setAttribute("aria-hidden", slotCount > 0);
    this.toggleTarget.checked = slotCount > 0;
  }
}
