import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "error"];

  connect() {
    this.min = parseInt(this.data.get("min") || 1);
  }

  checkedCount() {
    return this.checkboxTargets.filter((box) => box.checked).length;
  }

  validate(event) {
    if (this.checkedCount() < this.min) {
      this.renderError();
    } else {
      this.clearError();
    }
  }

  renderError() {
    const msg = this.errorTarget.getAttribute("data-error-msg");
    const span = document.createElement("span");
    span.textContent = msg;
    span.classList.add("error");
    this.errorTarget.innerHTML = "";
    this.errorTarget.appendChild(span);
    this.errorTarget.classList.remove("hidden");
  }

  clearError() {
    this.errorTarget.classList.add("hidden");
    this.errorTarget.innerHTML = "";
  }
}
