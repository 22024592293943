import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.chart = Chartkick.charts[this.data.get("id")];
  }

  changeGroupBy(e) {
    const groupBy = e.target.value;
    const endpoint = new URL(this.data.get("endpoint"));
    endpoint.searchParams.append("group_by", groupBy);
    this.chart.updateData(endpoint.href);
  }

  changeEndpoint(e) {
    const endpoint = e.target.value;
    this.chart.updateData(endpoint);
  }
}
