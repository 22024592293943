import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "menuButton"];

  initialize() {
    this.expanded = false;
  }

  handleKeydown(event) {
    if (!this.expanded && event.code !== "Escape") {
      return;
    }

    this.closeMenu();
  }

  handleClick(event) {
    if (!this.hasMenuTarget || (!this.expanded && event.target !== this.menuTarget)) {
      return;
    }

    this.closeMenu();
  }

  closeMenu() {
    this.expanded = false;
    this.menuButtonTarget.setAttribute("aria-expanded", false);
    this.menuTarget.setAttribute("aria-hidden", true);
    this.element.classList.remove("is-expanded");
  }

  openMenu() {
    this.expanded = true;
    this.menuButtonTarget.setAttribute("aria-expanded", true);
    this.menuTarget.setAttribute("aria-hidden", false);
    this.element.classList.add("is-expanded");
  }

  toggleMenu(event) {
    event.stopPropagation();
    this.expanded = !this.expanded;
    this.expanded ? this.openMenu() : this.closeMenu();
  }
}
