import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    scrollToBottom: Boolean,
  };

  static targets = ["scrollable"];

  connect() {
    if (this.scrollToBottomValue) {
      this.scrollToBottom();
    }
  }

  scrollToBottom() {
    console.log("scrolling to bottom");
    this.scrollableTarget.scrollTop = this.scrollableTarget.scrollHeight;
  }
}
