import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  COLOR_CLASS_PREFIX = "event-color-"; // String to prefix color class names with, e.g. "event-color-1"

  static values = {
    activityColor: Number, // Color class number to use for member activities
    colorsHash: Object, // Hash of organization IDs to color class numbers for the current account
    locationTypes: Array, // Enumerized event_type values from Event model
    organizationIds: Array, // ID of the current organization as an array or an array of the current user's organization IDs
    today: String, // Today's date as a string in YYYY-MM-DD format
  };

  static targets = [
    "chat", // The container holding the chat
    "coloredEvent", // Individual events on mobile and desktop
    "eventsDay", // Day with list of events on mobile
    "eventsList", // List containing all days and events on mobile
    "filterLocationTypeInput", // Checkbox for filtering event location type
    "filterMemberInput", // Checkbox for filtering member activities
    "filterOrganizationInput", // Checkbox for filtering organizations
    "filterSubmit", // Submit button for the filter form
    "filterToggle", // Button for toggling filtering by current chat or all organizations
    "selectedOrganization", // The header for the selected organization\'s chat
  ];

  initialize() {
    this.locationState = "all"; // Show in person and virtual events
    this.memberActivitesState = true; // Show member activities
    this.organizationsState = {};

    this.setOrganizationsState(this.organizationIdsValue); // Show all organizations
    this.showEventsDay();
    this.previewColorsHash = this.colorsHashValue; // Copy the colors hash to preview changes
  }

  setOrganizationsState(selectedOrganizationIds) {
    this.organizationIdsValue.forEach((organizationId) => {
      this.organizationsState[organizationId] = selectedOrganizationIds.includes(organizationId);
    });
  }

  updateVisibleEvents() {
    this.coloredEventTargets.forEach((eventElement) => {
      this.updateColoredEventVisibility(eventElement);
    });
  }

  eventShouldBeVisible(organizationId, eventType) {
    const organizationSelected = this.organizationsState[organizationId];
    const locationSelected = this.locationState === "all" || this.locationState === eventType;
    const memberActivitiesSelected = this.memberActivitesState || eventType !== "member";

    return organizationSelected && locationSelected && memberActivitiesSelected;
  }

  updateColoredEventVisibility(eventElement) {
    const { organizationId, eventType } = eventElement.dataset;
    const visible = this.eventShouldBeVisible(organizationId, eventType);
    eventElement.classList.toggle("hidden", !visible);
  }

  coloredEventTargetConnected(eventElement) {
    this.setColoredEventAttributes(eventElement);
    this.updateColoredEventVisibility(eventElement);
  }

  filterLocationTypeInputTargetConnected(inputElement) {
    const { value: locationType } = inputElement;
    const checked = this.locationState === locationType;
    inputElement.checked = checked;
  }

  filterMemberInputTargetConnected(inputElement) {
    const disabled = this.locationState !== "all";
    const checked = this.memberActivitesState;
    inputElement.disabled = disabled;
    inputElement.checked = checked && !disabled;
  }

  filterOrganizationInputTargetConnected(inputElement) {
    const { value: organizationId } = inputElement;
    const isSelectedState = this.organizationsState[organizationId];
    const checked = isSelectedState && !this.switchOrganizationOnChatChange;
    inputElement.checked = checked;
  }

  filterCheckedInputs(inputElements) {
    return inputElements.filter((inputElement) => {
      return inputElement.checked;
    });
  }

  mapInputValues(inputElements) {
    return inputElements.map((inputElement) => {
      return inputElement.value;
    });
  }

  setColoredEventAttributes(eventElement) {
    const { organizationId, eventType } = eventElement.dataset;

    let colorClass;

    if (eventType === "member") {
      colorClass = `event-color-${this.activityColorValue}`;
    } else {
      colorClass = `event-color-${this.colorsHashValue[organizationId]}`;
    }

    eventElement.setAttribute("data-color-class", colorClass);
    eventElement.classList.add(colorClass);
  }

  setFilteredLocationType() {
    const inputs = this.filterLocationTypeInputTargets;
    const checkedInput = this.filterCheckedInputs(inputs)[0];
    const selectedLocationType = checkedInput.value;
    this.locationState = selectedLocationType;
  }

  setFilteredOrganizations() {
    if (this.filterOrganizationInputTargets.length === 0) {
      return;
    }

    const selectedOrganizationInputs = this.filterCheckedInputs(this.filterOrganizationInputTargets);
    const selectedOrganizationIds = this.mapInputValues(selectedOrganizationInputs);

    this.setOrganizationsState(selectedOrganizationIds);
  }

  setFilteredMemberActivities() {
    const { checked, disabled } = this.filterMemberInputTarget;
    this.memberActivitesState = checked && !disabled;
  }

  dispatchCloseModalEvent() {
    const event = new CustomEvent("global-modal:close");
    window.dispatchEvent(event);
  }

  onFilterSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    this.setFilteredLocationType();
    this.setFilteredOrganizations();
    this.setFilteredMemberActivities();
    this.updateVisibleEvents();
    this.dispatchCloseModalEvent();
  }

  selectedAnyLocation() {
    return this.filterLocationTypeInputTargets.some((inputElement) => {
      return inputElement.checked;
    });
  }

  selectedAnyOrganization() {
    return this.filterOrganizationInputTargets.some((inputElement) => {
      return inputElement.checked;
    });
  }

  onLocationTypeFilterChange(event) {
    const selectedLocationType = event.currentTarget.value;
    const selectedAll = selectedLocationType === "all";
    this.filterMemberInputTarget.disabled = !selectedAll;
    this.updateFilterSubmitState();
  }

  onOrganizationFilterChange(event) {
    this.updateFilterSubmitState();
  }

  updateFilterSubmitState() {
    const validLocationSelection = this.selectedAnyLocation();
    const validOrganizationSelection = this.selectedAnyOrganization();

    const disabled = !(validLocationSelection && validOrganizationSelection);
    this.filterSubmitTarget.disabled = disabled;
  }

  resetFilterForm() {
    const allLocationTypeInput = this.filterLocationTypeInputTargets.find((inputElement) => {
      return inputElement.value === "all";
    });

    allLocationTypeInput.checked = true;
    this.filterMemberInputTarget.checked = true;
    this.filterMemberInputTarget.disabled = false;

    this.filterOrganizationInputTargets.forEach((inputElement) => {
      inputElement.checked = true;
    });

    this.onFilterSubmit();
  }

  findEventsDay(dateString) {
    return this.eventsDayTargets.find((day) => {
      return day.getAttribute("data-date") === dateString;
    });
  }

  showEventsDay(event) {
    let day;

    if (event) {
      const clickedDay = event.currentTarget;
      const date = clickedDay.getAttribute("data-date");
      day = this.findEventsDay(date);
    } else {
      day = this.findEventsDay(this.todayValue);
    }

    if (!day) return;

    day.scrollIntoView();
  }

  findOrganizationEvents(organizationId) {
    return this.coloredEventTargets.filter((eventElement) => {
      const { organizationId: eventOrganizationId } = eventElement.dataset;
      return organizationId === eventOrganizationId;
    });
  }

  previewColorOnOrganizationChat(organizationId, newColorClass) {
    const selectedOrganizationId = this.selectedOrganizationTarget.dataset.organizationId;

    if (organizationId !== selectedOrganizationId) {
      return;
    }

    const originalColorClass = this.selectedOrganizationTarget.dataset.originalColorClass;
    const currentColorClass = this.selectedOrganizationTarget.dataset.currentColorClass;

    this.selectedOrganizationTarget.classList.remove(originalColorClass);
    this.selectedOrganizationTarget.classList.remove(currentColorClass);
    this.selectedOrganizationTarget.classList.add(newColorClass);
    this.selectedOrganizationTarget.setAttribute("data-current-color-class", newColorClass);
  }

  previewColor(organizationId, newColorId) {
    const eventElements = this.findOrganizationEvents(organizationId).filter((eventElement) => {
      const { eventType } = eventElement.dataset;
      return eventType !== "member"; // Don't change member activity colors
    });

    const newColorClass = `event-color-${newColorId}`;
    this.previewColorOnOrganizationChat(organizationId, newColorClass);

    eventElements.forEach((eventElement) => {
      const oldColorClass =
        eventElement.getAttribute("data-preview-class") || eventElement.getAttribute("data-color-class");

      eventElement.classList.add(newColorClass);
      eventElement.classList.remove(oldColorClass);
      eventElement.setAttribute("data-preview-class", newColorClass);
    });

    this.previewColorsHash[organizationId] = newColorId;
  }

  handleColorChange(e) {
    const organizationId = e.currentTarget.getAttribute("data-organization-id");
    const newColorId = e.currentTarget.value;
    this.previewColor(organizationId, newColorId);
  }

  resetOrganizationChatColor() {
    const originalColorClass = this.selectedOrganizationTarget.dataset.originalColorClass;
    const previousColorClass = this.selectedOrganizationTarget.dataset.previousColorClass;

    this.selectedOrganizationTarget.classList.remove(previousColorClass);
    this.selectedOrganizationTarget.classList.add(originalColorClass);
  }

  resetEventColors() {
    this.coloredEventTargets.forEach((eventElement) => {
      const oldColorClass = eventElement.getAttribute("data-color-class");
      const previewColorClass = eventElement.getAttribute("data-preview-class");

      if (!previewColorClass) return;

      eventElement.removeAttribute("data-preview-class");

      if (oldColorClass === previewColorClass) return;

      eventElement.classList.add(oldColorClass);
      eventElement.classList.remove(previewColorClass);
    });

    this.resetOrganizationChatColor();
    this.previewColorsHash = this.colorsHashValue;
  }

  persistOrganizationChatColor() {
    const currentColorClass = this.selectedOrganizationTarget.dataset.currentColorClass;

    if (!currentColorClass) return;

    this.selectedOrganizationTarget.setAttribute("data-original-color-class", currentColorClass);
  }

  persistEventColors() {
    this.coloredEventTargets.forEach((eventElement) => {
      if (!eventElement.getAttribute("data-preview-class")) return;

      const newColorClass = eventElement.getAttribute("data-preview-class");
      eventElement.setAttribute("data-color-class", newColorClass);
      eventElement.removeAttribute("data-preview-class");
    });

    this.persistOrganizationChatColor();
    this.colorsHashValue = this.previewColorsHash;
  }
}
