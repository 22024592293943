import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

export default class extends Controller {
  static targets = [
    "interest",
    "submit",
    "error",
    "container",
    "btnUp",
    "btnDown",
  ];

  initialize() {
    this.containerTarget.addEventListener(
      "scroll",
      throttle(() => this.handleScroll(), 250)
    );
  }

  handleScroll() {
    const scrollPos = this.containerTarget.scrollTop;
    const scrollHeight = this.containerTarget.scrollHeight;

    if (scrollPos > scrollHeight / 2) {
      this.btnUpTarget.disabled = false;
      this.btnDownTarget.disabled = true;
    } else if (scrollPos < 50) {
      this.btnUpTarget.disabled = true;
      this.btnDownTarget.disabled = false;
    } else {
      this.btnUpTarget.disabled = false;
      this.btnDownTarget.disabled = false;
    }
  }

  jumpUp() {
    this.containerTarget.scroll({
      top: 0,
      behavior: "smooth",
    });
  }

  jumpDown() {
    this.containerTarget.scroll({
      top: this.containerTarget.scrollHeight,
      behavior: "smooth",
    });
  }

  validate() {
    const interestsSelected = this.interestTargets.filter(
      (checkbox) => checkbox.checked
    ).length;

    console.log(interestsSelected < parseInt(this.data.get("min")));

    if (interestsSelected < parseInt(this.data.get("min")))
      return this.disableSubmit();
    this.enableSubmit();
  }

  enableSubmit() {
    this.errorTarget.classList.add("hidden");
    this.submitTarget.disabled = false;
  }

  disableSubmit() {
    this.errorTarget.classList.remove("hidden");
    this.submitTarget.disabled = true;
  }
}
