import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dayOption", "daySection"];

  connect() {
    this.submitButtonSelectorValue =
      this.data.get("submitButtonSelectorValue") || "#continueBtn";
    this.toggleDay("sunday");
  }

  toggleDay(event) {
    const day = typeof event === "string" ? event : event.target.dataset.day;
    this.element.setAttribute("data-selected-day", day);
    this.updateAriaAttributes(day);
  }

  updateAriaAttributes(day) {
    this.dayOptionTargets.forEach((option) => {
      option.setAttribute("aria-selected", day === option.dataset.day);
    });

    this.daySectionTargets.forEach((section) => {
      section.setAttribute("aria-hidden", day !== section.dataset.day);
    });
  }

  timeSlotsChanged(event) {
    const { day } = event.detail;
    this.updateTabStyle(day);
    this.updateSubmitButton();
  }

  getAllPersistedTimeSlots() {
    return this.element.querySelectorAll(
      `[data-controller="manage-time-slot-entry"][data-persisted="true"]`
    );
  }

  getPersistedTimeSlots(day) {
    const persistedTimeSlots = this.getAllPersistedTimeSlots();
    return Array.from(persistedTimeSlots).filter(
      (slot) => slot.dataset.day === day
    );
  }

  getTab(day) {
    return this.dayOptionTargets.find((option) => option.dataset.day === day);
  }

  updateTabStyle(day) {
    const hasTimeSlots = this.getPersistedTimeSlots(day).length > 0;
    const tab = this.getTab(day);

    tab.classList.toggle("has-time-slots", hasTimeSlots);
  }

  updateSubmitButton() {
    const submitButton = document.querySelector(this.submitButtonSelectorValue);
    const hasTimeSlots = this.getAllPersistedTimeSlots().length > 0;

    if (!submitButton) {
      return;
    }

    submitButton.disabled = !hasTimeSlots;
  }
}
