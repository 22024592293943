import { Controller } from "@hotwired/stimulus";
import { findElement, flashNow, isHidden } from "../helpers";

export default class extends Controller {
  static targets = [
    "chatBtn", // Chat button in chat list, displays chat partner and preview of last message
    "chats", // Container that holds a list of chat buttons
    "frame", // Turbo frame that displays a chat's details
    "messages", // Container that holds a list of messages
  ];

  initialize() {
    this.syncChatButtons();

    const observerConfig = {
      attributes: false,
      childList: true,
      subtree: true,
    };

    // Listen for DOM changes to user's chat stream
    this.chatsObserver = new MutationObserver((mutationList, observer) =>
      this.handleMutation(mutationList, observer)
    );
    this.chatsObserver.observe(this.chatsTarget, observerConfig);
    this.frameTarget.addEventListener("turbo:frame-render", this.onFrameRender);
  }

  // Handle chat list mutations
  handleMutation = (mutationList, observer) => {
    for (const mutation of mutationList) {
      mutation.addedNodes.forEach((node) => {
        // Scroll to bottom only if a TURBO-FRAME element is added
        if (node.nodeName !== "TURBO-FRAME") return;

        // Parent element of the turbo stream is scrollable
        this.handleChatsUpdate();
        this.hideNotification(node);
      });
    }
  };

  // Fired whenever a new chat is rendered, only show the messages screen on mobile
  // after the new chat has been rendered to prevent a flash of the previous chat
  onFrameRender = (event) => {
    if (event.target !== this.frameTarget) return;

    const { response } = event.detail.fetchResponse;

    if (!response.ok) {
      flashNow("alert", "Failed to load chat");
      return;
    }

    this.showMessages();
  };

  handleChatClick(event) {
    const chatBtn = event.currentTarget;
    this.setSelected(chatBtn);
  }

  // Scroll to top whenever updates are made to chat list
  handleChatsUpdate() {
    this.chatsTarget.scroll({ top: 0, behavior: "smooth" });
    this.syncChatButtons();
  }

  // Show chats screen on mobile
  showChats() {
    this.element.classList.add("show-chats");
    this.element.classList.remove("show-messages");
  }

  showMessages() {
    this.element.classList.add("show-messages");
    this.element.classList.remove("show-chats");
  }

  // Set 'selected' class on clicked chat button
  setSelected(chatBtn) {
    this.clearSelected();
    chatBtn.classList.add("selected");
  }

  // Clear 'selected' style on all buttons with 'selected' class
  clearSelected() {
    this.chatBtnTargets
      .filter((chatBtn) => chatBtn.classList.contains("selected"))
      .forEach((chatBtn) => chatBtn.classList.remove("selected"));
  }

  findChatButton(chatId) {
    return findElement(this.chatsTarget, `#chat-btn_${chatId}`);
  }

  syncChatButtons() {
    if (!this.hasMessagesTarget) return;
    const chatId = this.messagesTarget.getAttribute("data-chat-window-id");
    this.setSelected(this.findChatButton(chatId));
  }

  jumpToNewestMessage() {
    this.messagesTarget.scroll({
      top: this.messagesTarget.scrollHeight,
      behavior: "smooth",
    });
  }

  // Notifications are marked as read asychronously when the message is scrolled into view
  // Hide new notification bubbles for the current chat currently in the viewport
  hideNotification(node) {
    if (!this.messagesTarget) return;
    const chatId = this.messagesTarget.getAttribute("data-chat-window-id");

    if (node.id !== chatId || isHidden(this.messagesTarget)) return;
    const avatar = findElement(node, ".chat-card__avatar");
    avatar.classList.remove("has-notifications");
  }

  disconnect() {
    this.chatsObserver.disconnect();
    this.frameTarget.removeEventListener(
      "turbo:frame-render",
      this.onFrameRender
    );
  }
}
