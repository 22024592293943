import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["messages"];

  static values = {
    senderId: String,
  };

  initialize() {
    this.initializeMessageObserver();
  }

  initializeMessageObserver() {
    this.messageObserver = new MutationObserver((mutationList, observer) =>
      this.handleMessageMutation(mutationList, observer)
    );

    const observerConfig = {
      attributes: false,
      childList: true,
      subtree: true,
    };

    this.messageObserver.observe(this.messagesTarget, observerConfig);
  }

  handleMessageMutation(mutationList, observer) {
    for (const mutation of mutationList) {
      mutation.addedNodes.forEach((node) => {
        if (node.nodeName !== "TURBO-FRAME") return;

        const turboFrame = node;
        const isNewMessage = turboFrame.dataset.newMessage === "true";
        const isSameSender = turboFrame.dataset.senderId === this.senderIdValue;

        if (!isNewMessage) {
          return;
        }

        if (isSameSender) {
          turboFrame.remove();
          return;
        }

        this.handleScroll(this.messagesTarget, "smooth");
      });
    }
  }

  handleScroll(element, behavior = "smooth") {
    element.scroll({
      top: element.scrollHeight,
      behavior,
    });
  }

  disconnect() {
    if (!this.messageObserver) return;

    this.messageObserver.disconnect();
  }
}
