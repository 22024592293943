import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["required", "submit"];

  initialize() {
    this.withUpload = this.data.get("withUpload") === "true";
    this.disableSubmit = this.data.get("disableSubmit") === "true";
    this.validate();
  }

  validate() {
    let formComplete = this.requiredTargets.every((input) =>
      this.validateInput(input)
    );

    if (this.withUpload) {
      formComplete = formComplete && this.uploadComplete();
    }

    if (formComplete) {
      this.enableContinue();
    } else {
      this.disableContinue();
    }
  }

  validateInput(input) {
    switch (input.type) {
      case "checkbox":
        return input.checked;
      case "radio":
        return this.validateRadio(input);
      default:
        return this.validateMinLength(input);
    }
  }

  validateRadio(input) {
    const radioGroup = this.element.querySelectorAll(
      `input[name="${input.name}"]`
    );
    return Array.from(radioGroup).some((radio) => radio.checked);
  }

  validateMinLength(input) {
    const minLength = input.minLength > 0 ? input.minLength : 1;
    return input.value.trim().length >= minLength;
  }

  uploadComplete() {
    const processedItems = this.element.querySelectorAll(".dz-processing");
    const processedItemsArray = Array.from(processedItems);
    if (processedItemsArray.length === 0) return false;

    return processedItemsArray.every((item) => {
      return item.classList.contains("dz-success");
    });
  }

  enableContinue() {
    this.submitTargets.forEach((submit) => {
      submit.classList.remove("disabled");
    });

    if (!this.disableSubmit) return;

    this.submitTargets.forEach((submit) => {
      submit.disabled = false;
    });
  }

  disableContinue() {
    this.submitTargets.forEach((submit) => {
      submit.classList.add("disabled");
    });

    if (!this.disableSubmit) return;

    this.submitTargets.forEach((submit) => {
      submit.disabled = true;
    });
  }
}
