import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "error", "modalLink"];

  initialize() {
    this.removeCount = 0;
  }

  validate(e) {
    const interestCount = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked
    ).length;

    if (interestCount > parseInt(this.data.get("min"))) {
      const targetId = e.target.getAttribute("data-interest-id");
      this.removeCount++;
      this.updateModalParams(targetId);
      this.uncheckInput(targetId);
    } else {
      this.errorTarget.classList.remove("hidden");
    }
  }

  updateModalParams(interestId) {
    console.log(this.removeCount);
    const interestParam = `${
      this.removeCount === 1 ? "?" : "&"
    }interest_ids%5B%5D=${interestId}`;
    this.modalLinkTarget.href = this.modalLinkTarget.href + interestParam;
  }

  uncheckInput(interestId) {
    const targetInterest = this.checkboxTargets.find(
      (checkbox) => checkbox.value === interestId
    );

    if (!targetInterest) return;
    targetInterest.checked = false;
  }
}
