import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";
import { SCREEN_MD } from "../breakpoints";

export default class extends Controller {
  static targets = ["button", "panel"];

  initialize() {
    this.active = false;
    window.addEventListener(
      "resize",
      throttle(() => this.onResize(), 100)
    );
  }

  toggle() {
    if (window.innerWidth >= SCREEN_MD) return;

    this.active = !this.active;

    if (this.active) {
      this.expand();
    } else {
      this.contract();
    }
  }

  expand() {
    this.buttonTarget.setAttribute("aria-expanded", "true");
    this.panelTarget.setAttribute("aria-hidden", "false");
    this.setHeight();
  }

  contract() {
    this.buttonTarget.setAttribute("aria-expanded", "false");
    this.panelTarget.setAttribute("aria-hidden", "true");
    this.panelTarget.style.maxHeight = "0px";
  }

  setHeight() {
    this.panelTarget.style.maxHeight = this.panelTarget.scrollHeight + "px";
  }

  onResize() {
    if (!this.active) return;

    if (window.innerWidth >= SCREEN_MD) return this.expand();

    this.setHeight();
  }

  disconnect() {
    window.removeEventListener(
      "resize",
      throttle(() => this.onResize())
    );
  }
}
