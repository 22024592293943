import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["list"];

  toggleOnboardingData() {
    this.listTarget.classList.toggle("onboarding-view");
  }

  scrollToListTop() {
    this.listTarget.scrollIntoView({
      behavior: "auto",
      block: "start",
      inline: "nearest",
    });
  }
}
