import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    signedIn: Boolean,
    signInUrl: String,
  };

  static targets = ["textarea", "formActions", "submit"];

  connect() {
    this.isSignedIn = this.signedInValue;

    if (!this.isSignedIn) {
      return;
    }

    this.textareaTarget.removeAttribute("readonly");
  }

  isExpanded() {
    return this.formActionsTarget.getAttribute("aria-hidden") === "false";
  }

  expandActions() {
    this.formActionsTarget.setAttribute("aria-hidden", false);
    this.textareaTarget.setAttribute("aria-expanded", true);
  }

  collapseActions() {
    this.formActionsTarget.setAttribute("aria-hidden", true);
    this.textareaTarget.setAttribute("aria-expanded", false);
  }

  onFocus(event) {
    if (this.isExpanded() || !this.isSignedIn) {
      event.preventDefault();
      return;
    }

    this.expandActions();
  }

  onInput(event) {
    const trimmedValue = event.target.value.trim();

    this.submitTarget.disabled = trimmedValue.length === 0;
  }

  onCancel() {
    this.textareaTarget.value = "";
    this.submitTarget.disabled = true;
    this.collapseActions();
  }

  onClick() {
    if (this.isSignedIn) {
      return;
    }

    window.location.href = this.signInUrlValue;
  }
}
