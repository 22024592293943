import { Controller } from "@hotwired/stimulus";
import { SCREEN_MD } from "../breakpoints";
import { flashNow } from "../helpers";
import { findElement } from "../helpers";

export default class extends Controller {
  static targets = ["input", "locationBtn", "latitude", "longitude"];

  connect() {
    this.processing = false;
    this.headerTarget = findElement("header.header");
    this.formTarget = findElement(this.element, "form");
    this.initialValues = this.getInitialValues();
  }

  getInitialValues() {
    const valueHash = {};
    this.inputTargets.forEach((input) => {
      valueHash[input.name] = input.value;
    });
    return valueHash;
  }

  // Exit form if clicking outside of the form
  handleClick(e) {
    if (
      window.innerWidth < SCREEN_MD ||
      (e && this.element.contains(e.target)) ||
      this.processing
    )
      return;
    this.hideForm();
    this.resetForm();
  }

  requiredInputs() {
    return this.inputTargets.filter((input) => !input.disabled);
  }

  changesMade() {
    return this.requiredInputs().some(
      (input) => input.value !== this.initialValues[input.name]
    );
  }

  focusFirstInput() {
    if (this.requiredInputs().length === 0) return;
    this.requiredInputs()[0].focus();
  }

  submitStart(e) {
    if (this.changesMade()) {
      this.processing = true;
    } else {
      e.detail.formSubmission.stop();
      flashNow("notice", "Update cancelled");
      this.hideForm();
    }
  }

  submitEnd(e) {
    this.processing = false;
    if (!e.detail.success) return;
    this.hideForm();
  }

  showForm() {
    this.scrollToTop();
    this.element.classList.add("active");
    setTimeout(() => {
      this.headerTarget.classList.add("viewing-form");
      this.focusFirstInput();
      document.body.classList.add("mobile-viewport");
    });
  }

  hideForm() {
    this.scrollToTop();
    this.element.classList.remove("active");
    this.headerTarget.classList.remove("viewing-form");
    document.body.classList.remove("mobile-viewport");
  }

  resetForm() {
    // Set inputs to original values
    this.inputTargets.forEach((input) => {
      if (input.nodeName !== "SELECT") {
        input.value = this.initialValues[input.name];
      }
    });

    // Remove errors generated by rails
    this.element.querySelectorAll(".error").forEach((el) => el.remove());
  }

  scrollToTop() {
    if (window.innerWidth >= SCREEN_MD) return;
    window.scrollTo({ top: 0 });
  }

  fetchLocation() {
    this.locationBtnTarget.classList.add("loading");

    const error = (err) => {
      this.locationBtnTarget.classList.remove("loading");
      this.locationBtnTarget.textContent = err.message;
    };

    const success = (pos) => {
      this.latitudeTarget.value = pos.coords.latitude;
      this.longitudeTarget.value = pos.coords.longitude;
      setTimeout(() => this.formTarget.requestSubmit());
    };

    navigator.geolocation.getCurrentPosition(success, error);
  }
}
